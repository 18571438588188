import { API_PATH } from '../../constants'
import { vipApi } from '../../utils/vip-api'

type getBonusByCodeReq = {
  code: string
  payment_method_id: number
}

export const getBonusByCode = async (data: getBonusByCodeReq): Promise<Request> => {
  return await vipApi(API_PATH.CASHIER.GET_BONUS_BY_CODE, 'post', data)
}
