import React, { ChangeEvent } from 'react'

interface CheckboxProps {
  classNames?: { label: string }
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  label: string
}

const CustomCheckbox: React.FC<CheckboxProps> = ({ classNames, checked, onChange, label }) => {
  return (
    <div className='mb-4'>
      <label className={`font-light text-gray-400 ${classNames?.label} flex items-center cursor-pointer`}>
        <div className='relative'>
          <input type='checkbox' id='terms' className='sr-only' checked={checked} onChange={onChange} />
          <div
            className={`w-6 h-6 border-2 rounded-sm ${
              checked ? 'border-[#c8a964] bg-[#c8a964]' : 'border-gray-400'
            } flex items-center justify-center`}
          >
            {checked && (
              <svg className='w-4 h-4 text-white fill-current' viewBox='0 0 20 20'>
                <path d='M0 11l2-2 5 5L18 3l2 2L7 18z' />
              </svg>
            )}
          </div>
        </div>
        <span className='ml-2'>
          {label}{' '}
          <a className='font-medium text-primary-600 hover:underline' href='#'>
            Terms and Conditions
          </a>
        </span>
      </label>
    </div>
  )
}

export default CustomCheckbox
