import axios from 'axios'

export function vipApi(path: string, requestType: string, data: any, reqType?: string): any {
  const params = new FormData()
  Object.keys(data).forEach((key) => {
    const value = data[key]
    params.append(key, value)
  })

  const headers = {
    'Content-Type': reqType === 'json' ? 'application/json' : 'multipart/form-data',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }

  const asiOptions = {
    method: requestType,
    headers,
    data: reqType === 'json' ? data : params,
  }

  // TODO Fix
  const url = 'https://api-vip-sandbox.pdub.io/api' + path

  return axios(url, asiOptions)
    .then((response) => response.data)
    .catch((error) => {
      console.error('API Error:', error)
      throw error
    })
}
