import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { ChevronUp, ChevronDown, CircleAlert } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

import { ContTable } from '../cashier-home-page/components/ContTable'
import { ContDialog } from '../cashier-home-page/components/ContDiaglog'
// import { VipCrownIcon } from '../../../shared/icons/crown-icon';
import { PickListBonusGame } from '../../../shared/components/bonus/bonus-list-picks'

import { IBonus } from '../../../models/Bonus/bonus'

import { BonusGameWinnerEnum, BonusTeamEnumEnum } from '../../../enum/bonus/bonus-game-winner-enum'
import { TransactionTypeEnum } from '../../../enum/transactions/TransactionTypeEnum'
import { PaymentMethodEnum } from '../../../enum/transactions/PaymentMethodEnum'
import { BonusAmountTypeEnum } from '../../../enum/bonus/bonus-type-amount-enum'
import { CashierRoutes } from '../../../enum/routes/routes-site-enum'
import { useTransaction } from '../../../providers/TransactionContext'
import useSWR from 'swr'
import { API_PATH } from '../../../constants'
import { getBonusByCode } from '../../../api/bonus/bonus-by-code'
import './bonus-select-page.scss'
import { useToast } from '../../../providers/ToastContext'

export interface BonusSelectPageProps {
  setCurrentRoute: Dispatch<SetStateAction<CashierRoutes>>
  bonus: any
  availableBonuses: any
  isLoading: boolean
  rollover: any
  user: any
}

export const BonusSelectPage = (props: BonusSelectPageProps) => {
  const { setCurrentRoute, bonus, availableBonuses, isLoading, rollover, user } = props

  const { transactionType, setTransactionBonus, transactionMethod, setIsWelcomeBonus } = useTransaction()
  const { data: playerProfileSettingsPaymentMethodBonuses } = useSWR<IBonus[] | undefined>(
    API_PATH.CASHIER.LIST_PLAYER_PROFILE_SETTINGS_PAYMENT_METHOD_BONUSES,
  )
  const { data: ListPlayerProfileSettingsPaymentMethodBonusesAction } = useSWR(
    API_PATH.CASHIER.LIST_PLAYER_PROFILE_SETTINGS_PAYMENT_METHOD_BONUSES,
  )

  const { addToast } = useToast()

  // const param = `?player_number=${user.username}&method=${transactionMethod?.id}`
  // const { data: bonuses } = useSWR(API_PATH.CASHIER.GET_BONUSES(param))

  const [showDetails, setShowDetails] = useState(0)
  const [promoCode, setPromoCode] = useState('')
  const [contOpen, setContOpen] = useState(false)
  const [contData, setContData] = useState([])
  const [showCodeBox, setShowCodeBox] = useState(false)
  const [isSubmitingCode, setIsSubmitingCode] = useState(false)

  useEffect(() => {
    if (transactionMethod?.id != PaymentMethodEnum.BONUS) {
      setIsWelcomeBonus(false)
    } else {
      setIsWelcomeBonus(true)
      ListPlayerProfileSettingsPaymentMethodBonusesAction()
    }
  }, [])

  let isOnBonus = null
  if (rollover != undefined) {
    isOnBonus = true
  } else {
    isOnBonus = false
  }

  if (transactionType == 1 && bonus != null) {
    setCurrentRoute(CashierRoutes.INFO)
  }

  const handleGetBonusByCode = async () => {
    if (promoCode.length < 1) {
      return
    }
    setIsSubmitingCode(true)
    try {
      await getBonusByCode({ code: promoCode, payment_method_id: Number(transactionMethod?.id) })
      addToast('Bonus claimed correctly', 'success')
    } catch (error: any) {
      addToast(error?.response?.data?.statusMessage ?? 'An error occurred when claiming a bonus', 'error')
    } finally {
      setIsSubmitingCode(false)
    }
  }

  return (
    <>
      {transactionMethod && (
        <div className='container mx-auto px-4 py-6 mb-12 '>
          <h2 className='text-2xl b-border-2 text-center mb-4 pb-4 text-primary border-b border-primary'>
            Select a {transactionType == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'} Bonus
          </h2>

          {transactionMethod?.id != PaymentMethodEnum.BONUS && (
            <div className='bg-grey-300 border-l-4 border-yellow-500 text-yellow-400 p-4 text-justify'>
              <p className='text-sm'>
                <CircleAlert className='inline mr-2' /> If you would like to deposit without a bonus, you can skip by
                pressing the button below.
              </p>
            </div>
          )}

          {!isLoading ? (
            <div className='space-y-4'>
              <ContDialog
                open={contOpen}
                onClose={() => {
                  setContData([])
                  setContOpen(false)
                }}
                data={<ContTable rows={contData && contData != null ? contData : []} />}
              />

              {isOnBonus && (
                <div className='bg-grey-300 border-l-4 border-yellow-500 text-yellow-400 p-4 text-justify'>
                  <p className='text-sm'>
                    <CircleAlert className='inline mr-2' /> It looks like you are already on a bonus. You cannot redeem
                    a bonus while on a current rollover.
                  </p>
                </div>
              )}

              <div className='flex justify-between mb-4'>
                <button
                  className='bg-transparent border-2 border-primary hover:opacity-50 text-white font-bold py-2 px-4 rounded'
                  onClick={() => {
                    setTransactionBonus(null)
                    setCurrentRoute(CashierRoutes.METHOD)
                  }}
                >
                  Back
                </button>

                {transactionMethod?.id != PaymentMethodEnum.BONUS && (
                  <button
                    className='bg-primary text-white hover:opacity-50 py-2 px-4 rounded'
                    onClick={() => {
                      setTransactionBonus(null)
                      setCurrentRoute(CashierRoutes.INFO)
                    }}
                  >
                    Skip
                  </button>
                )}
              </div>

              {availableBonuses.length > 0 ? (
                availableBonuses.map((bonus: any) => (
                  <div key={bonus.id} className='bg-white shadow-md rounded-lg overflow-hidden mb-4'>
                    <div
                      className='p-4 cursor-pointer'
                      onClick={() => {
                        setShowDetails(showDetails === bonus.id ? 0 : bonus.id)
                      }}
                    >
                      <div className='flex justify-between items-center'>
                        <div className='text-gray-600' dangerouslySetInnerHTML={{ __html: bonus.description }} />
                        <div className='hidden sm:flex items-center'>
                          {bonus.id == showDetails ? (
                            <ChevronUp className='text-gray-500' />
                          ) : (
                            <p className='text-xs flex items-center'>
                              Show more
                              <ChevronDown className='text-gray-500 ml-1' />
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='flex justify-between items-center mt-2'>
                        <span
                          className={`px-2 py-1 rounded-full text-xs font-semibold ${
                            bonus.type == 'Free Play' ? 'bg-blue-100 text-blue-800' : 'bg-red-100 text-red-800'
                          }`}
                        >
                          {bonus.type}
                        </span>
                        <div className='sm:hidden'>
                          {bonus.id == showDetails ? (
                            <ChevronUp className='text-gray-500' />
                          ) : (
                            <p className='text-xs flex items-center'>
                              Show more
                              <ChevronDown className='text-gray-500 ml-1' />
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {bonus.id === showDetails && (
                      <div className='p-4 bg-yellow-50 border-t border-yellow-100'>
                        {(bonus.category == 'gradedgames' || bonus.category == 'riskfreebet') && (
                          <>
                            <h3 className='text-lg font-semibold mb-2'>Bonus Terms</h3>
                            <div className='text-sm mb-4' dangerouslySetInnerHTML={{ __html: bonus.terms }}></div>

                            {bonus?.bonus_games && Array.isArray(bonus.bonus_games) && (
                              <div className='space-y-2'>
                                <div className='grid grid-cols-9 gap-2 font-semibold text-sm'>
                                  <div className='col-span-6'>LINE</div>
                                  <div className='col-span-3'>MIN BET AMOUNT</div>
                                </div>
                                {bonus.bonus_games.map((x: any, index: number) => (
                                  <div key={index} className='border border-yellow-300 p-2 rounded'>
                                    {(x.winner == BonusGameWinnerEnum.VISITOR || !x.winner) && (
                                      <>
                                        <div className='grid grid-cols-9 gap-2 text-sm'>
                                          <div className='col-span-6'>
                                            #{x.vnum} {x.visitor}
                                          </div>
                                          <div className='col-span-3'>${x.min_amount}</div>
                                        </div>
                                        <PickListBonusGame BonusOrRollover={x} Team={BonusTeamEnumEnum.VISITOR} />
                                      </>
                                    )}
                                    {(x.winner == BonusGameWinnerEnum.HOME || !x.winner) && (
                                      <>
                                        <div className='grid grid-cols-9 gap-2 text-sm'>
                                          <div className='col-span-6'>
                                            #{x.hnum} {x.home}
                                          </div>
                                          <div className='col-span-3'>${x.min_amount}</div>
                                        </div>
                                        <PickListBonusGame BonusOrRollover={x} Team={BonusTeamEnumEnum.HOME} />
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        )}

                        {bonus.category == 'deposit' && (
                          <>
                            <h3 className='text-lg font-semibold mb-2'>Wagering Requirement Before Withdrawals</h3>
                            <div className='space-y-2 text-sm'>
                              <p>For a bonus with {bonus.rollover_multiplier * 1}x Rollover:</p>
                              <p>
                                $100 Deposit + ${bonus.match_multiplier * 100} Bonus = $
                                {bonus.match_multiplier * 100 + 100}
                              </p>
                              <p>
                                ${bonus.match_multiplier * 100 + 100} x {Math.floor(bonus.rollover_multiplier)}x
                                Rollover Multiplier = $
                                {bonus.rollover_multiplier * (bonus.match_multiplier * 100 + 100)}
                              </p>
                              <p>
                                This means you must wager a total of $
                                {bonus.rollover_multiplier * (bonus.match_multiplier * 100 + 100)} to be eligible for a
                                Withdrawal.
                              </p>
                            </div>
                          </>
                        )}

                        <div className='grid grid-cols-2 gap-4 mt-4'>
                          {bonus.category == 'deposit' && (
                            <button
                              className='bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded'
                              onClick={() => {
                                setContData(bonus.contribution_table)
                                setContOpen(true)
                              }}
                            >
                              Contribution
                            </button>
                          )}
                          <button
                            className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
                            onClick={() => {
                              setTransactionBonus(bonus)
                              if (bonus.id == 97) {
                                if (confirm('You are about to redeem a CASINO ONLY bonus. Are you sure?')) {
                                  setCurrentRoute(CashierRoutes.INFO)
                                }
                              } else {
                                setCurrentRoute(CashierRoutes.INFO)
                              }
                            }}
                          >
                            Claim Bonus
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : playerProfileSettingsPaymentMethodBonuses && playerProfileSettingsPaymentMethodBonuses?.length > 0 ? (
                playerProfileSettingsPaymentMethodBonuses?.map((bonus: any) => (
                  <div key={bonus.id} className='bg-white shadow-md rounded-lg overflow-hidden mb-4'>
                    <div
                      className='p-4 cursor-pointer'
                      onClick={() => {
                        setShowDetails(showDetails === bonus.id ? 0 : bonus.id)
                      }}
                    >
                      <div className='flex justify-between items-center'>
                        <div className='text-gray-600' dangerouslySetInnerHTML={{ __html: bonus.description }} />
                        <div className='hidden sm:flex items-center'>
                          {bonus.id == showDetails ? (
                            <ChevronUp className='text-gray-500' />
                          ) : (
                            <p className='text-xs flex items-center'>
                              Show more
                              <ChevronDown className='text-gray-500 ml-1' />
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='flex justify-between items-center mt-2'>
                        <span
                          className={`px-2 py-1 rounded-full text-xs font-semibold ${
                            bonus.type == 'Free Play' ? 'bg-blue-100 text-blue-800' : 'bg-red-100 text-red-800'
                          }`}
                        >
                          {bonus.type}
                        </span>
                        <div className='sm:hidden'>
                          {bonus.id == showDetails ? (
                            <ChevronUp className='text-gray-500' />
                          ) : (
                            <p className='text-xs flex items-center'>
                              Show more
                              <ChevronDown className='text-gray-500 ml-1' />
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {bonus.id === showDetails && (
                      <div className='p-4 bg-yellow-50 border-t border-yellow-100'>
                        {(bonus.category == 'gradedgames' || bonus.category == 'riskfreebet') && (
                          <>
                            <h3 className='text-lg font-semibold mb-2'>Bonus Terms</h3>
                            <div className='text-sm mb-4' dangerouslySetInnerHTML={{ __html: bonus.terms }}></div>

                            {bonus?.bonus_games && Array.isArray(bonus.bonus_games) && (
                              <div className='space-y-2'>
                                <div className='grid grid-cols-9 gap-2 font-semibold text-sm'>
                                  <div className='col-span-6'>LINE</div>
                                  <div className='col-span-3'>MIN BET AMOUNT</div>
                                </div>
                                {bonus.bonus_games.map((x: any, index: number) => (
                                  <div key={index} className='border border-yellow-300 p-2 rounded'>
                                    {(x.winner == BonusGameWinnerEnum.VISITOR || !x.winner) && (
                                      <>
                                        <div className='grid grid-cols-9 gap-2 text-sm'>
                                          <div className='col-span-6'>
                                            #{x.vnum} {x.visitor}
                                          </div>
                                          <div className='col-span-3'>${x.min_amount}</div>
                                        </div>
                                        <PickListBonusGame BonusOrRollover={x} Team={BonusTeamEnumEnum.VISITOR} />
                                      </>
                                    )}
                                    {(x.winner == BonusGameWinnerEnum.HOME || !x.winner) && (
                                      <>
                                        <div className='grid grid-cols-9 gap-2 text-sm'>
                                          <div className='col-span-6'>
                                            #{x.hnum} {x.home}
                                          </div>
                                          <div className='col-span-3'>${x.min_amount}</div>
                                        </div>
                                        <PickListBonusGame BonusOrRollover={x} Team={BonusTeamEnumEnum.HOME} />
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}

                            <div className='mt-4 space-y-1 text-sm'>
                              <p className='font-semibold'>Bonus Details:</p>
                              <p>
                                Deposit: Min ${bonus.min_deposit} - Max ${bonus.max_deposit}
                              </p>
                              {bonus.category == 'riskfreebet' && (
                                <p>
                                  {bonus.type_amount_id == BonusAmountTypeEnum.FIXED
                                    ? `Freeplay-back: $${bonus.match_multiplier * 100}`
                                    : `Freeplay-back: $${bonus.max_deposit} Risk * ${
                                        bonus.match_multiplier * 100
                                      }% = $${bonus.max_deposit * bonus.match_multiplier}`}
                                </p>
                              )}
                            </div>
                            <p className='text-xs mt-2'>*See Bonus Rules for complete terms and Conditions</p>
                          </>
                        )}

                        {bonus.category == 'deposit' && (
                          <>
                            <h3 className='text-lg font-semibold mb-2'>
                              For this ${bonus.min_deposit} NON-DEPOSIT Welcome Bonus
                            </h3>
                            <div className='space-y-2 text-sm'>
                              <p>
                                ${bonus.min_deposit} Bonus x {bonus.rollover_multiplier}x Rollover Multiplier = $
                                {bonus.min_deposit * bonus.rollover_multiplier}
                              </p>
                              <p>
                                This means you must wager a total of ${bonus.min_deposit * bonus.rollover_multiplier} to
                                be eligible for a Withdrawal
                              </p>
                            </div>
                          </>
                        )}

                        <div className='grid grid-cols-2 gap-4 mt-4'>
                          {bonus.category == 'deposit' && (
                            <button
                              className='bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded'
                              onClick={() => {
                                setContData(bonus.contribution_table)
                                setContOpen(true)
                              }}
                            >
                              Contribution
                            </button>
                          )}
                          <button
                            className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
                            onClick={() => {
                              setTransactionBonus(bonus)
                              if (bonus.id == 97) {
                                if (confirm('You are about to redeem a CASINO ONLY bonus. Are you sure?')) {
                                  setCurrentRoute(CashierRoutes.INFO)
                                }
                              } else {
                                setCurrentRoute(CashierRoutes.INFO)
                              }
                            }}
                          >
                            Claim Bonus
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className='text-xl text-center text-gray-200'>There are no available bonuses.</p>
              )}

              {transactionMethod?.id != PaymentMethodEnum.BONUS && (
                <div className='bg-gray-700 shadow-md rounded-lg overflow-hidden'>
                  <div
                    className='px-6 flex items-center justify-between cursor-pointer'
                    onClick={() => setShowCodeBox(!showCodeBox)}
                  >
                    <p className='text-white text-xl flex justify-center items-center'>
                      <div className='gift'></div> Use special promo code below!
                    </p>

                    {showCodeBox ? <ChevronUp className='text-gray-500' /> : <ChevronDown className='text-gray-500' />}
                  </div>
                  <AnimatePresence initial={false}>
                    {showCodeBox && (
                      <motion.div
                        className='p-4 bg-gray-700'
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.4, ease: 'easeInOut' }}
                      >
                        <input
                          className='w-full p-2 border border-gray-300 rounded mb-2 text-gray-800'
                          placeholder='Promo Code'
                          value={promoCode}
                          onChange={(e) => setPromoCode(e.target.value)}
                        />
                        <button
                          className='w-full bg-primary hover:bg-opacity-50 text-white font-bold py-2 px-4 rounded'
                          onClick={handleGetBonusByCode}
                        >
                          Submit
                        </button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              )}
            </div>
          ) : (
            <div className='text-center'>
              <div className='animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 mx-auto'></div>
              <p className='mt-4 text-gray-600'>Loading Bonuses...</p>
            </div>
          )}
        </div>
      )}
    </>
  )
}
