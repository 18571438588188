import React, { Dispatch, SetStateAction } from 'react' // Reemplazo de `getHistory().push('/')`
import { TransactionTypeEnum } from '../../../enum/transactions/TransactionTypeEnum'
import { CashierRoutes } from '../../../enum/routes/routes-site-enum'
import { useTransaction } from '../../../providers/TransactionContext'
import { CircleCheck } from 'lucide-react'

export interface ExternalProps {
  setCurrentRoute: Dispatch<SetStateAction<CashierRoutes>>
}

export const CompletePage: React.FC<ExternalProps> = (props) => {
  const { setCurrentRoute } = props

  const { transaction, resetTransaction } = useTransaction()

  return (
    <div className='max-w-lg mx-auto pb-3 px-2 bg-black rounded-md shadow-lg mt-4'>
      {transaction?.transaction_type_id === TransactionTypeEnum.WITHDRAWAL ? (
        <div className='bg-gray-800 border-l-4 border-primary text-primary p-4 rounded-md'>
          <CircleCheck className='inline mr-2 mb-1' />
          <div className='text-lg inline'>Payment Sent</div>
          <p>Your payment has been sent. Thank you!</p>
        </div>
      ) : (
        <div className='bg-gray-800 border-l-4 border-primary text-primary p-4 rounded-md'>
          <CircleCheck className='inline mr-2 mb-1' />
          <div className='text-lg inline'>Payment Received</div>
          <p>Your payment has been received. Thank you!</p>
        </div>
      )}
      <div className='mt-4 flex justify-center'>
        <button
          className='bg-primary text-white px-6 py-2 rounded-md shadow hover:opacity-50 transition duration-300'
          onClick={() => {
            resetTransaction()
            setCurrentRoute(CashierRoutes.CASHIER)
          }}
        >
          Back to Cashier
        </button>
      </div>
    </div>
  )
}
