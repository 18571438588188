import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { IpProviderResponse } from '../../models/ip'
import { IPlayerSession } from '../../models/player'
import { SocketRequestLuckyOrange } from '../../models/Socket/socket-request'
import { FingerprintEnum } from '../../enum/fingerprint/fingerprint'
export const GetFormattedDate = (date: string): string => {
  if (!date.includes('T')) {
    const tempDate = date.split(' ')
    date = `${tempDate[0]}T${tempDate[1]}Z`
  }

  const dateFormat = new Date(date)

  const year = dateFormat.getFullYear()

  let month = (1 + dateFormat.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  let day = dateFormat.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  const minutes = (dateFormat.getMinutes() < 10 ? '0' : '') + dateFormat.getMinutes().toString()
  const hour = (dateFormat.getHours() < 10 ? '0' : '') + dateFormat.getHours().toString()
  const seconds = (dateFormat.getSeconds() < 10 ? '0' : '') + dateFormat.getSeconds().toString()

  return month + '/' + day + '/' + year + ' ' + hour + ':' + minutes + ':' + seconds
}

export const GetAmountWithOutDecimals = (number: unknown): number => {
  if (typeof number === 'number') {
    return truncateNumber(number, 0)
  }

  if (typeof number === 'string') {
    return truncateNumber(Number(number.replace(/,/g, '')), 0)
  }

  return 0
}

export const truncateNumber = (number: number, intDecimalPlaces: number): number => {
  const numPower = Math.pow(10, intDecimalPlaces)
  return ~~(number * numPower) / numPower
}

export const GetAmountWithDecimals = (number: unknown, decimals: number): number => {
  if (typeof number === 'number') {
    return truncateNumber(number, decimals)
  }

  if (typeof number === 'string') {
    return truncateNumber(Number(number.replace(/,/g, '')), decimals)
  }

  return 0
}

export const formatUSDNumber = (number: number) => {
  try {
    const tempNumber = parseFloat(number.toString().replace(/,/g, ''))
    return tempNumber.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  } catch (error) {
    return number
  }
}

export const GetFingerPrint = async () => {
  return FingerprintJS.load({ token: 'nvoiB2OqFxGoBIg53MC6' })
    .then((fp) => fp.get())
    .then((result) => {
      localStorage.setItem('fpjs', result.visitorId)
      return result.visitorId
    })
    .catch((ex) => {
      console.log('GetFingerPrint', { ex })
      return FingerprintEnum.DEFAULT
    })
}

export const GetIp = () => {
  return fetch(`https://api.ipstack.com/check?access_key=3b6261dad08b9cba55ec27a51c4cc97b&security=1&hostname=1`)
    .then((response) => {
      return response.json()
    })
    .then((res) => {
      localStorage.setItem('geo', JSON.stringify(res))
      return <IpProviderResponse>res
    })
    .catch((err) => {
      console.error('Problem fetching my IP', err)
      return null
    })
}

export const GetLuckyOrangSession = () => {
  return new Promise((resolve: (value: SocketRequestLuckyOrange | null) => void) => {
    setTimeout(() => {
      try {
        // console.log('window LO', window.LO);
        const result = <SocketRequestLuckyOrange>window.LO.session.get()
        resolve(result)
      } catch (error) {
        resolve(null)
        console.log('Error GetLuckyOrangSession', { error })
      }
    }, 1500)
  })
}

export const removeHTMLTags = (remove: string) => {
  let tmpRemove = remove
  const removeValues: string[] = ['<BR>', '<br>', '\n', '<p>', '</p>', '&nbsp;']
  try {
    removeValues.forEach((x) => {
      if (tmpRemove.includes(x)) {
        tmpRemove = tmpRemove.replaceAll(x, ' ')
      }
    })
  } catch (error) {
    console.log({ error })
  }
  return tmpRemove
}

export const GetLocalUser = (): string | null => {
  let username: string | null = null
  try {
    const result: string | null = localStorage.getItem('user')
    if (result) {
      const user = <IPlayerSession | null>JSON.parse(result)
      username = user?.username ?? null
    }
  } catch (error) {
    console.log('MarkNotifiedAsReadPlayerAction', { error })
  }
  return username
}

export const GetLocalFingerPrint = (): string | null => {
  let username: string | null = null
  try {
    username = localStorage.getItem('fpjs') ?? FingerprintEnum.DEFAULT
  } catch (error) {
    console.log('MarkNotifiedAsReadPlayerAction', { error })
  }
  return username
}

export const GetTimeZone = (): string | null => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (error) {
    console.log('GetTimeZone', { error })
  }
  return null
}
