export class HttpUtil {
  static instance: HttpUtil | null = null

  token: string | null = null

  constructor() {
    this.token = localStorage.getItem('token')
  }

  static async getInstance(): Promise<HttpUtil> {
    if (!HttpUtil.instance) {
      HttpUtil.instance = new HttpUtil()
    }
    return HttpUtil.instance
  }

  async setHeaders(options: any = null): Promise<Headers> {
    try {
      const headers = new Headers()
      headers.append('Accept', options?.accept || 'application/json')
      headers.append('Content-Type', options?.contentType || 'application/json')
      headers.append('Authorization', await this.getToken())
      return headers
    } catch (error) {
      return Promise.reject("We are sorry, but we couldn't process your request. Please try again later. Code: A-002")
    }
  }

  getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.token) {
        resolve(`Bearer ${this.token}`)
      } else {
        reject(new Error("We are sorry, but we couldn't process your request. Please try again later. Code: A-001"))
      }
    })
  }
}
