import React from 'react'
import { PLAY_LINE } from '../../../enum'
import { CheckCircle, XCircle } from 'lucide-react'
import { BonusGameWinnerEnum } from '../../../enum/bonus/bonus-game-winner-enum'

export const ValidatePickBonus = (BonusGame: any, PlayLine: any): boolean | null => {
  if (!BonusGame) return null
  switch (PlayLine) {
    case PLAY_LINE.VISITORSPREAD:
      return (
        (BonusGame.use_v_spread && BonusGame.winner === BonusGameWinnerEnum.VISITOR) ||
        (BonusGame.use_v_spread && !BonusGame.winner)
      )

    case PLAY_LINE.VISITORMONEYLINE:
      return (
        (BonusGame.use_v_moneyline && BonusGame.winner === BonusGameWinnerEnum.VISITOR) ||
        (BonusGame.use_v_moneyline && !BonusGame.winner)
      )

    case PLAY_LINE.VISITORTOTAL:
      return (
        (BonusGame.use_v_total && BonusGame.winner === BonusGameWinnerEnum.VISITOR) ||
        (BonusGame.use_v_total && !BonusGame.winner)
      )

    case PLAY_LINE.HOMESPREAD:
      return (
        (BonusGame.use_h_spread && BonusGame.winner === BonusGameWinnerEnum.HOME) ||
        (BonusGame.use_h_spread && !BonusGame.winner)
      )

    case PLAY_LINE.HOMEMONEYLINE:
      return (
        (BonusGame.use_h_moneyline && BonusGame.winner === BonusGameWinnerEnum.HOME) ||
        (BonusGame.use_h_moneyline && !BonusGame.winner)
      )

    case PLAY_LINE.HOMETOTAL:
      return (
        (BonusGame.use_h_total && BonusGame.winner === BonusGameWinnerEnum.HOME) ||
        (BonusGame.use_h_total && !BonusGame.winner)
      )

    default:
      return null
  }
}

export const PickBonusGame = (props: any) => {
  const { BonusGame, PlayLine } = props

  const SuccessIconOdd = () => {
    return <CheckCircle className='w-6 h-6 text-green-500' />
  }
  const ErrorIconOdd = () => {
    return <XCircle className='w-6 h-6 text-red-500' />
  }

  if (BonusGame) {
    const validationResult = ValidatePickBonus(BonusGame, PlayLine)

    switch (validationResult) {
      case true:
        return <SuccessIconOdd />
      case false:
        return <ErrorIconOdd />
      default:
        return null
    }
  }

  return null
}
