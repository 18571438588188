import React from 'react'
import { PLAY_LINE } from '../../../enum'
import { PickBonusGame } from './bonus-valid-picks'
import { BonusTeamEnumEnum } from '../../../enum/bonus/bonus-game-winner-enum'
import { BadgeCheck, DollarSign, Layers } from 'lucide-react' // Importamos los íconos de lucide-react

export const PickListBonusGame = (props: any) => {
  const { BonusOrRollover, Team } = props

  const iconMap = {
    SPREAD: <Layers className='w-5 h-5 mr-1 text-gray-700' />,
    'MONEY LINE': <DollarSign className='w-5 h-5 mr-1 text-gray-700' />,
    TOTAL: <BadgeCheck className='w-5 h-5 mr-1 text-gray-700' />,
  }

  const renderPickOption = (lineType: any, playLine: any) => (
    <div className='flex items-center justify-center p-2 bg-gray-200 rounded-md m-1'>
      {/* @ts-ignore FIX */}
      {iconMap[lineType]}
      <PickBonusGame BonusGame={BonusOrRollover} PlayLine={playLine} />
      <span className='ml-1 text-sm font-semibold'>{lineType}</span>
    </div>
  )

  return (
    <>
      {Team === BonusTeamEnumEnum.VISITOR && (
        <div className='flex flex-col space-y-2'>
          {renderPickOption('SPREAD', PLAY_LINE.VISITORSPREAD)}
          {renderPickOption('MONEY LINE', PLAY_LINE.VISITORMONEYLINE)}
          {renderPickOption('TOTAL', PLAY_LINE.VISITORTOTAL)}
        </div>
      )}
      {Team === BonusTeamEnumEnum.HOME && (
        <div className='flex flex-col space-y-2'>
          {renderPickOption('SPREAD', PLAY_LINE.HOMESPREAD)}
          {renderPickOption('MONEY LINE', PLAY_LINE.HOMEMONEYLINE)}
          {renderPickOption('TOTAL', PLAY_LINE.HOMETOTAL)}
        </div>
      )}
    </>
  )
}
