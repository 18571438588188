import React, { ChangeEvent, useState, FC, useRef } from 'react'
import { PhoneInput } from './../../libs/react-international/'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { domains, Email as EmailAutoComplete } from '@smastrom/react-email-autocomplete'
import './phoneInput.css'
import './signUpForm.scss'

import { CountryName } from '../../utils/countryCodes'
import { isArray, isEmailValid, isPasswordValid } from '../../utils/validators'
import Input from '../Input'
import Checkbox from '../Checkbox'
import Button from '../Button'
import useAlertModal from '../../hooks/useAlertModal'
import PasswordInput from '../PasswordInput'
import { emailBaseList } from '../../utils/emailBaseList'

// Error codes
const ERROR_CODES = {
  EMAIL_EXISTS: -9,
  INVALID_EMAIL: -10,
  PHONE_EXISTS: -11,
  INVALID_PHONE: -12,
  IS_NOT_MOBILE_PHONE: -13,
  INVITE_CODE_DOES_NOT_EXIST: -14,
  PHONE_AND_EMAIL_EXISTS: -15,
  INVALID_PASSWORD: -16,
}

type E164Number = any

const phoneUtil = PhoneNumberUtil.getInstance()

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export interface SignupFormProps {
  apiUrl: string
  onSuccessCallback: (responseData: any) => void
  onBackClick: () => void
  fingerprint?: string
  ip?: string
  isLoading?: boolean
  inviteLinkQueryParam?: string
}

const SignupForm: FC<SignupFormProps> = ({
  apiUrl,
  onSuccessCallback,
  fingerprint,
  ip,
  onBackClick,
  isLoading,
  inviteLinkQueryParam,
}) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [inviteLink, setInviteLink] = useState<null | string>(inviteLinkQueryParam ?? null)
  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>(undefined)
  const [agreed, setAgreed] = useState(false)
  const [triggerFormAttemp, setTriggerFormAttemp] = useState(false)
  const [isApiLoading, setIsApiLoading] = useState(false)
  const [errorApi, setErrorApi] = useState('')

  const [emailTouched, setEmailTouched] = useState(false)
  const [firstNameTouched, setFirstNameTouched] = useState(false)
  const [lastNameTouched, setLastNameTouched] = useState(false)
  const [passwordTouched, setPasswordTouched] = useState(false)
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false)
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false)

  // Refs for input fields
  const emailRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const inviteLinkRef = useRef<HTMLInputElement>(null)

  const showAlert = useAlertModal({
    title:
      "We're sorry but the invite code isn't valid, if you want to continue the signup process please click on the OK button.",
    confirmButtonText: 'OK',
    onConfirm: () => handleSignup(true),
  })

  const isValid = isPhoneValid(phoneNumber)
  const isFirstNameValid = firstName.length >= 2
  const isLastNameValid = lastName.length >= 2
  const isPasswordsMatch = password === confirmPassword

  const isFormValid =
    isFirstNameValid &&
    isLastNameValid &&
    isEmailValid(email) &&
    isPasswordValid(password) &&
    isPasswordsMatch &&
    isValid &&
    agreed

  const handleSignup = async (defaultInviteCode?: boolean) => {
    setIsApiLoading(true)
    if (!triggerFormAttemp) {
      setTriggerFormAttemp(true)
    }

    if (!isFormValid) {
      setEmailTouched(true)
      setFirstNameTouched(true)
      setLastNameTouched(true)
      setPasswordTouched(true)
      setConfirmPasswordTouched(true)
      setPhoneNumberTouched(true)
      setIsApiLoading(false)
      return
    }

    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber)
    const countryCode = parsedNumber.getCountryCode()
    const regionCode = phoneUtil.getRegionCodeForCountryCode(Number(countryCode))
    let nationalNumber
    if (parsedNumber) {
      nationalNumber = parsedNumber.getNationalNumber()
    }
    const countryData = {
      name: CountryName[regionCode],
      iso2: regionCode.toLowerCase(),
      dialCode: countryCode,
      priority: 0,
      areaCodes: null,
    }

    const data: any = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: nationalNumber,
      fullPhone: phoneNumber,
      country_code: countryCode,
      password,
      password_confirmation: confirmPassword,
      inviter_code: inviteLink || 'VIPBB',
      fingerprint: fingerprint,
      ip: ip,
      countryData,
    }

    if (defaultInviteCode) {
      data.default_invite_code = true
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })

      if (response.ok) {
        const responseData = await response.json()
        if (isArray(responseData?.data)) {
          if (responseData.statusCode === ERROR_CODES.INVITE_CODE_DOES_NOT_EXIST) {
            showAlert()
          } else {
            setErrorApi(responseData?.messages?.[0]?.msg)
            handleErrorFocus(responseData.statusCode)
          }
        } else {
          onSuccessCallback(responseData?.data)
        }
      } else {
        const responseData = await response.json()
        if (responseData.statusCode === ERROR_CODES.INVITE_CODE_DOES_NOT_EXIST) {
          showAlert()
        } else {
          setErrorApi(responseData?.messages?.[0]?.msg)
          handleErrorFocus(responseData.statusCode)
        }
      }
    } catch (error) {
      console.error('Server Error:', error)
    } finally {
      setIsApiLoading(false)
    }
  }

  const handleErrorFocus = (errorCode: number) => {
    console.log('errorCode :>> ', errorCode)
    switch (errorCode) {
      case ERROR_CODES.EMAIL_EXISTS:
      case ERROR_CODES.INVALID_EMAIL:
      case ERROR_CODES.PHONE_AND_EMAIL_EXISTS:
        emailRef.current?.focus()
        break
      case ERROR_CODES.PHONE_EXISTS:
      case ERROR_CODES.INVALID_PHONE:
      case ERROR_CODES.IS_NOT_MOBILE_PHONE:
        phoneRef.current?.focus()
        break
      case ERROR_CODES.INVALID_PASSWORD:
        passwordRef.current?.focus()
        break
      case ERROR_CODES.INVITE_CODE_DOES_NOT_EXIST:
        inviteLinkRef.current?.focus()
        break
      default:
        console.log('not mappable error')
        // No specific field to focus
        break
    }
  }
  // @ts-expect-error
  window.handleErrorFocus = handleErrorFocus

  // @ts-expect-error
  window.emailRef = emailRef

  return (
    <div className='mainContainer'>
      <div className='signupContainer'>
        <div className='signupImage'></div>
        <div className='signupForm'>
          <div className='backButtonContainer'>
            <button onClick={onBackClick} className='backButton'>
              ← Back
            </button>
          </div>
          <h1>Sign Up</h1>
          <PhoneInput
            placeholder='Phone Number'
            defaultCountry='us'
            value={phoneNumber}
            error={phoneNumberTouched && (phoneNumber === undefined || phoneNumber.length <= 5 || !isValid)}
            onChange={(phone) => {
              setPhoneNumber(phone)
              setPhoneNumberTouched(false)
            }}
            inputRef={phoneRef}
          />
          {phoneNumber !== undefined && phoneNumber?.length > 5 && !isValid && (
            <div className='errorDiv'>Phone is not valid</div>
          )}
          <div className='mb-4 inputContainer relative' style={{ width: '100%' }}>
            <label className='block text-white-700 text-sm font-light my-2' htmlFor='email'>
              Email
            </label>
            <EmailAutoComplete
              id='email'
              placeholder='Enter your email'
              baseList={emailBaseList}
              refineList={domains}
              value={email}
              onChange={setEmail}
              onBlur={() => setEmailTouched(true)}
              className={`
                bg-white border text-gray-900 sm:text-sm rounded-lg 
                block w-full p-1.5
                focus:outline-none focus:ring-2 focus:ring-[#c8a964] focus:border-[#c8a964]
                bordered
                ${emailTouched && !isEmailValid(email) ? 'border-red-500' : 'border-gray-300'}
              `}
              classNames={{
                input: `w-full border-transparent focus:outline-none`,
                dropdown: `absolute bg-white mt-4 p-1.5 rounded-lg z-50 w-full ml-0 -translate-x-1.5 border-2 border-gray-300`,
                suggestion: `hover:opacity-70 cursor-pointer`,
              }}
              ref={emailRef}
            />
            {emailTouched && !isEmailValid(email) && <div className='errorDiv'>Email is not valid</div>}
          </div>
          <div className='inputGroups'>
            <Input
              label='First Name'
              id='first_name'
              required
              placeholder='Enter your first name'
              variant='bordered'
              value={firstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
              onBlur={() => setFirstNameTouched(true)}
              width='48%'
              error={firstNameTouched && !isFirstNameValid}
            />
            <Input
              label='Last Name'
              id='last_name'
              required
              placeholder='Enter your last name'
              variant='bordered'
              value={lastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
              onBlur={() => setLastNameTouched(true)}
              width='48%'
              error={lastNameTouched && !isLastNameValid}
            />
          </div>

          {firstNameTouched && !isFirstNameValid && (
            <div className='errorDiv'>First name must have at least 2 characters</div>
          )}
          {lastNameTouched && !isLastNameValid && (
            <div className='errorDiv'>Last name must have at least 2 characters</div>
          )}

          <div className='inputGroups'>
            <PasswordInput
              label='Password'
              id='password'
              required
              placeholder='Enter your password'
              type='password'
              variant='bordered'
              value={password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              onBlur={() => setPasswordTouched(true)}
              width='48%'
              error={passwordTouched && !isPasswordValid(password)}
              ref={passwordRef}
            />
            <PasswordInput
              label='Confirm Password'
              id='confirm_password'
              required
              placeholder='Confirm your password'
              type='password'
              variant='bordered'
              value={confirmPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
              onBlur={() => setConfirmPasswordTouched(true)}
              width='48%'
              error={confirmPasswordTouched && !isPasswordsMatch}
            />
          </div>

          {passwordTouched && !isPasswordValid(password) && (
            <div className='errorDiv'>
              Password must have at least 8 characters, one letter, one number, and one special character
            </div>
          )}
          {confirmPasswordTouched && !isPasswordsMatch && <div className='errorDiv'>Passwords do not match</div>}
          <Input
            label='Invite Code'
            id='invite_code'
            placeholder='Enter your invite Code'
            variant='bordered'
            value={inviteLink ?? ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setInviteLink(e.target.value)}
            ref={inviteLinkRef}
          />
          <div className='flex py-2 px-1'>
            <Checkbox
              checked={agreed}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAgreed(e.target.checked)}
              label='I agree to VIP CLUB Terms and Conditions'
            />
          </div>
          {triggerFormAttemp && !agreed && <div className='errorDiv'>ㅤㅤYou must accept terms and conditions</div>}

          {errorApi && <div className='errorDiv'>{errorApi}</div>}

          <div className='block'>
            <div className='w-full block mb-5'>
              <Button
                label='Continue'
                color='primary'
                className='w-full mt-5'
                onClick={() => handleSignup()}
                isLoading={isApiLoading || isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupForm
