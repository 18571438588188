export const formatTimeRange = (startTime: string, endTime: string): string => {
  const formatTime = (time: string): string =>
    new Date(`2024-01-01T${time}:00`).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })

  return `${formatTime(startTime)} to ${formatTime(endTime)}`
}
