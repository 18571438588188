import React from 'react'

export interface ExternalProps {
  rows: any
  bonus: any
}

export default function ConfirmTable(props: ExternalProps) {
  const { rows, bonus } = props

  return (
    <div className='overflow-hidden rounded-lg bg-gray-800 border-2 border-primary shadow-md'>
      <table className='min-w-full bg-gray-800'>
        <tbody>
          {rows
            .filter((row: any) => {
              if (bonus == null) {
                return row.dataType !== ' Bonus'
              } else {
                return true
              }
            })
            .map((row: any, index: number) => (
              <tr key={index} className='border-b border-gray-800'>
                <th scope='row' className='text-left py-2 px-4 font-medium text-white'>
                  {row.dataType}
                </th>
                <td className='text-right py-2 px-4 text-white'>{row.dataAmount}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
