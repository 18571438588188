import React, { useEffect, useState } from 'react'
import DataTableComponent, { Column } from '../Datatable'
import './Loyalty.scss'
import { calculateTimeRemaining, getWeekNumber } from '../../utils/dateTime'
import { BoostCard, BoostCardProps, BoostItem } from './BoostCard'
import { calculateDateRange, onClaimBonus } from './helper'
import AnimationsModal from '../AnimationModal'
import { useAnimationsModal } from '../../hooks/useAnimationModal'
import { ChevronDown, ChevronUp } from 'lucide-react'
import useSWR from 'swr'

type LoyaltyType = {
  apiUrl: string
  ftpUrl: string
  onSuccess: () => void
}

const Loyalty: React.FC<LoyaltyType> = ({ apiUrl, ftpUrl, onSuccess }) => {
  const initialDateRange = calculateDateRange(0)
  const [startDate, setStartDate] = useState(initialDateRange?.startDate || '2024-01-01')
  const [endDate, setEndDate] = useState(initialDateRange?.endDate || '2024-07-17')
  const [historyId, setHistoryId] = useState<number>(1)

  const { isVisible, message, state, showLoading, showSuccess, showError } = useAnimationsModal()

  const {
    data: loyaltyData,
    mutate: mutateLoyaltyData,
    isLoading: loadingCards,
  } = useSWR(`${apiUrl}/loyalty-program/user?is_quick_view=0`)

  const { data: historyData, isLoading: loadingHistory } = useSWR(
    `${apiUrl}/loyalty-program/user/history/${historyId}?start_date=${startDate}&end_date=${endDate}&loyalty_program_boost_status_id`,
  )

  useEffect(() => {
    const newDateRange = calculateDateRange(historyId)
    if (newDateRange) {
      setStartDate(newDateRange.startDate)
      setEndDate(newDateRange.endDate)
    }
  }, [historyId])

  const handleFetchClaimBonus = (item: BoostItem) => {
    if (item && item.id) {
      showLoading('Claiming bonus...')
      onClaimBonus(item.id, apiUrl)
        .then(() => {
          console.log('Bonus claimed successfully')

          mutateLoyaltyData()
            .then(() => {
              setTimeout(() => onSuccess(), 2500)
              showSuccess(
                `Bonus claimed successfully! <br />Your loyalty funds have been added to your bonus balance: $${
                  item.amount ?? '-'
                }. <br />Thank you for your continued loyalty!`,
              )
            })
            .catch((err: any) => {
              console.error('Error mutating loyalty data:', err)
            })
        })
        .catch((e: any) => {
          const errorMessage = e ?? 'An error occurred. Please try again later.Code:000'
          showError(errorMessage)
        })
    }
  }

  const [boostData, setBoostData] = useState<BoostCardProps[]>([])
  const [selectedCardIndex, setSelectedCardIndex] = useState(0)
  const [showHistory, setShowHistory] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 910)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 910)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const updateBoostData = () => {
      setBoostData(mapBoostData(loyaltyData))
    }

    updateBoostData()
    const intervalId = setInterval(updateBoostData, 1000)

    return () => clearInterval(intervalId)
  }, [loyaltyData])

  const mapBoostData = (loyaltyData: any): BoostCardProps[] => {
    if (!loyaltyData || !loyaltyData.data || !loyaltyData.data[0] || !loyaltyData.data[0].loyalty_programs) {
      return []
    }

    return loyaltyData.data[0].loyalty_programs.map((program: any) => {
      let items: BoostItem[] = []
      let iconUrl = ''
      let nextBoost = ''
      let historyId = ''
      let category = 'Bronze'

      const timeRemaining = calculateTimeRemaining(program.next_execution_date)
      const tierImage =
        program.loyalty_program_by_user.loyalty_program_profile.loyalty_program_profile_tier.loyalty_program_tier.image
      const categoryName =
        program.loyalty_program_by_user.loyalty_program_profile.loyalty_program_profile_tier.loyalty_program_tier.name

      iconUrl = `${ftpUrl}${tierImage}`

      if (program.name === 'Daily Boost') {
        category = categoryName
        nextBoost = `Next Daily Boost: ${timeRemaining}`
        items = program.loyalty_program_boosts.map((boost: any) => ({
          id: boost.loyalty_program_boost_by_user.id,
          name: boost.name,
          status: boost.loyalty_program_boost_by_user.loyalty_program_boost_by_user_statuses
            .name as BoostItem['status'],
          amount: boost.loyalty_program_boost_by_user.loyalty_program_boost_by_user_final_amount as BoostItem['amount'],
        }))
        historyId = program.id
      } else if (program.name === 'Weekly Impulse') {
        category = categoryName
        nextBoost = `Next Weekly Boost: ${timeRemaining}`
        items = program.loyalty_program_boosts.map((boost: any) => ({
          id: boost.loyalty_program_boost_by_user.id,
          name: `Week ${getWeekNumber(new Date(boost.start_date))}`,
          status: boost.loyalty_program_boost_by_user.loyalty_program_boost_by_user_statuses
            .name as BoostItem['status'],
          amount: boost.loyalty_program_boost_by_user.loyalty_program_boost_by_user_final_amount as BoostItem['amount'],
        }))
        historyId = program.id
      } else if (program.name === 'Monthly Impulse') {
        category = categoryName
        nextBoost = `Next Monthly Boost: ${timeRemaining}`
        items = program.loyalty_program_boosts.map((boost: any) => ({
          id: boost.loyalty_program_boost_by_user.id,
          name: new Date(boost.start_date).toLocaleString('default', { month: 'long' }),
          status: boost.loyalty_program_boost_by_user.loyalty_program_boost_by_user_statuses
            .name as BoostItem['status'],
          amount: boost.loyalty_program_boost_by_user.loyalty_program_boost_by_user_final_amount as BoostItem['amount'],
        }))
        historyId = program.id
      }

      return {
        title: program.name.toUpperCase(),
        iconUrl,
        category,
        nextBoost,
        items,
        historyId,
        setHistoryId,
      }
    })
  }
  const tableColumns: Column[] = [
    {
      title: 'Day',
      data: 'start_date',
      format: 'date',
    },
    { title: '', data: 'name' },
    {
      title: 'Amount',
      data: 'loyalty_program_boost_by_user.loyalty_program_boost_by_user_final_amount',
      format: 'amount',
    },
    { title: 'Status', data: 'loyalty_program_boost_by_user.loyalty_program_boost_by_user_statuses.name' },
    {
      title: 'Claimed At',
      data: 'loyalty_program_boost_by_user.loyalty_program_boost_by_user_claimed_at',
      format: 'date',
    },
  ]

  const tableData = historyData?.data?.loyalty_program_boosts ?? []

  const SkeletonCard = () => (
    <div className='bg-background rounded-lg p-4 w-64 h-[550px] flex flex-col relative skeleton-loading'>
      <div className='absolute top-0 left-0 right-0 bg-primary h-[50px] rounded-b-lg'></div>
      <div className='flex justify-center mb-4 mt-8'>
        <div className='w-16 h-16 rounded-full bg-gray-700'></div>
      </div>
      <div className='w-3/4 h-4 bg-gray-700 mx-auto mb-8'></div>
      <div className='space-y-2 flex-grow'>
        {[...Array(5)].map((_, index) => (
          <div key={index} className='flex justify-between items-center'>
            <div className='w-1/2 h-4 bg-gray-700'></div>
            <div className='w-1/4 h-8 bg-gray-700 rounded'></div>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div className='bg-black min-h-screen p-8'>
      <AnimationsModal isVisible={isVisible} message={message} state={state} />
      <div className='custom:hidden mb-4'>
        <div className='flex justify-between items-center bg-background rounded-lg p-4'>
          {/* remove this later */}
          {/*           <select
            className='bg-gray-800 text-white rounded p-2 w-[180px]'
            value={selectedCardIndex}
            onChange={(e) => setSelectedCardIndex(Number(e.target.value))}
          >
            {boostData.map((boost, index) => (
              <option key={index} value={index}>
                {boost.title}
              </option>
            ))}
          </select> */}
          <button
            className='bg-primary text-white rounded py-1 px-3 text-sm w-full flex items-center justify-center'
            onClick={() => setShowHistory(!showHistory)}
          >
            {showHistory ? 'Back' : 'History'}
            {showHistory ? (
              <ChevronUp scale={2} className='ml-2 w-4 h-4 text-white' />
            ) : (
              <ChevronDown scale={2} className='ml-2 w-4 h-4 text-white' />
            )}
          </button>
        </div>
      </div>
      <div className='custom:flex custom:flex-wrap custom:justify-center custom:gap-8 mb-8'>
        {loadingCards && window.innerWidth <= 910 ? (
          <>
            <div className='custom:hidden flex justify-center'>
              <SkeletonCard />
            </div>
            <div className='hidden custom:flex custom:flex-wrap custom:justify-center custom:gap-8'>
              {[...Array(1)].map((_, index) => (
                <SkeletonCard key={index} />
              ))}
            </div>
          </>
        ) : (
          <div className='custom:hidden flex justify-center'>
            {boostData.length > 0 && (
              <BoostCard
                {...boostData[selectedCardIndex]}
                isMobile={isMobile}
                showHistory={showHistory}
                onClaimBonus={handleFetchClaimBonus}
                loadingHistory={loadingHistory}
              />
            )}
          </div>
        )}
      </div>

      <div className='hidden custom:flex custom:flex-row custom:items-start custom:justify-center custom:gap-8 w-full'>
        {/* BoostCard */}
        {loadingCards && window.innerWidth >= 910 ? (
          <SkeletonCard />
        ) : (
          <>
            {boostData.slice(0, 1).map((boost, index) => (
              <BoostCard
                key={index}
                {...boost}
                isMobile={isMobile}
                showHistory={showHistory}
                onClaimBonus={handleFetchClaimBonus}
                loadingHistory={loadingHistory}
              />
            ))}
          </>
        )}

        {(showHistory || window.innerWidth >= 910) && (
          <div className='flex flex-col bg-background p-4 rounded-md'>
            <div className='flex flex-row gap-4 mb-4'>
              <div>
                <label className='text-white mr-2'>Start Date</label>
                <input
                  type='date'
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className='bg-gray-800 text-white rounded p-1'
                />
              </div>
              <div>
                <label className='text-white mr-2'>End Date</label>
                <input
                  type='date'
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className='bg-gray-800 text-white rounded p-1'
                />
              </div>
            </div>
            {loadingHistory ? (
              <div className='skeleton-loading'>
                <div className='h-10 bg-gray-700 mb-2'></div>
                {[...Array(5)].map((_, index) => (
                  <div key={index} className='h-8 bg-gray-700 mb-2'></div>
                ))}
              </div>
            ) : (
              <DataTableComponent columns={tableColumns} data={tableData} />
            )}
          </div>
        )}
      </div>

      {/* showHistory ||
                (window.innerWidth >= 910 && */}
      {showHistory && window.innerWidth <= 910 && (
        <div className='bg-background rounded-lg p-4 max-w-[840px] mx-auto'>
          <div className='flex justify-between mb-4'>
            <div className='flex gap-4'>
              <div>
                <label className='text-white mr-2'>Start Date</label>
                <input
                  type='date'
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className='bg-gray-800 text-white rounded p-1'
                />
              </div>
              <div>
                <label className='text-white mr-2'>End Date</label>
                <input
                  type='date'
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className='bg-gray-800 text-white rounded p-1'
                />
              </div>
            </div>
            {!isMobile && <h2>History</h2>}
          </div>
          {loadingHistory ? (
            <div className='skeleton-loading'>
              <div className='h-10 bg-gray-700 mb-2'></div>
              {[...Array(5)].map((_, index) => (
                <div key={index} className='h-8 bg-gray-700 mb-2'></div>
              ))}
            </div>
          ) : (
            <DataTableComponent columns={tableColumns} data={tableData} />
          )}
        </div>
      )}
    </div>
  )
}

export default Loyalty
