import { Dispatch, SetStateAction } from 'react'
import { HttpUtil } from '../../utils/http-util'

type FetchLoyaltyDataType = {
  setLoyaltyData: Dispatch<SetStateAction<any>>
  setLoadingCards: Dispatch<SetStateAction<boolean>>
  apiUrl: string
}

type FetchHistoryDataType = {
  startDate: string
  endDate: string
  historyId: number | null
  setHistoryData: Dispatch<SetStateAction<any>>
  setLoadingHistory: Dispatch<SetStateAction<boolean>>
  apiUrl: string
}

const commonFetch = async (url: string, options: RequestInit) => {
  const response = await fetch(url, options)

  if (!response.ok) {
    const errorData = await response.json().catch(() => null)
    const errorMessage =
      errorData?.messages?.[0]?.msg || `Request failed with status ${response.status}: ${response.statusText}`
    throw new Error(errorMessage)
  }

  const result = await response.json()
  return result
}

export const onClaimBonus = async (id: number, apiUrl: string): Promise<void> => {
  const httpInstance = await HttpUtil.getInstance()

  const myHeaders = await httpInstance.setHeaders()

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
  }

  try {
    const result = await commonFetch(`${apiUrl}/loyalty-program/claim/${id}`, requestOptions)
    console.log('Bonus claimed successfully:', result)
    return Promise.resolve()
  } catch (error: any) {
    return Promise.reject(error)
  }
}

export function calculateDateRange(historyId: number): { startDate: string; endDate: string } | null {
  const today = new Date()
  let newStartDate: Date
  let newEndDate: Date

  switch (historyId) {
    case 1:
      newStartDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000) // 1 week before today
      newEndDate = new Date(today.getTime()) // Today
      break
    case 2:
      newStartDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000) // 1 week before today
      newEndDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000) // 1 week after today
      break
    case 3:
      newStartDate = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000) // 2 weeks before today
      newEndDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000) // 1 week after today
      break
    case 4:
      newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1) // First day of previous month
      newEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0) // Last day of current month
      break
    default:
      return null // No changes for other historyId values
  }

  return {
    startDate: newStartDate.toISOString().split('T')[0],
    endDate: newEndDate.toISOString().split('T')[0],
  }
}
