import React, { createContext, useContext, useState, useEffect } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

interface UserMetadataContextType {
  ip: string | null
  fingerprint: string | null
}

const UserMetadataContext = createContext<UserMetadataContextType>({
  ip: null,
  fingerprint: null,
})

export const useUserMetadata = () => useContext(UserMetadataContext)

export const UserMetadataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ip, setIp] = useState<string | null>(null)
  const [fingerprint, setFingerprint] = useState<string | null>(null)

  useEffect(() => {
    // Fetch IP address
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        setIp(data.ip)
      } catch (error) {
        console.error('Error fetching IP:', error)
      }
    }

    // Fetch fingerprint
    const fetchFingerprint = async () => {
      try {
        const fp = await FingerprintJS.load({ token: 'nvoiB2OqFxGoBIg53MC6' })
        const result = await fp.get()
        setFingerprint(result.visitorId)
      } catch (error) {
        console.error('Error fetching fingerprint:', error)
        setFingerprint(null)
      }
    }

    if (!ip) {
      fetchIp()
    }

    if (!fingerprint) {
      fetchFingerprint()
    }
  }, [ip, fingerprint])

  return <UserMetadataContext.Provider value={{ ip, fingerprint }}>{children}</UserMetadataContext.Provider>
}
