import React from 'react'
import { CashierRoutes } from '../../../enum/routes/routes-site-enum'

type MainWrapperProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  currentStep: CashierRoutes
}

const MultiStepIndicator: React.FC<{ currentStep: CashierRoutes }> = ({ currentStep }) => {
  const steps = [
    { id: CashierRoutes.CASHIER, label: 'Step 1', description: 'Select Transaction Method' },
    { id: CashierRoutes.METHOD, label: 'Step 2', description: 'Select Payment Method' },
    { id: CashierRoutes.BONUS, label: 'Step 3', description: 'Select Bonus' },
    { id: CashierRoutes.INFO, label: 'Step 4', description: 'Enter Transaction Info' },
    { id: CashierRoutes.CONFIRM, label: 'Step 5', description: 'Confirm Transaction' },
  ]

  const stepIndex = steps.findIndex((s) => s.id === currentStep)

  return (
    <ol className='lg:flex items-center w-full space-y-2 lg:space-y-0 lg:space-x-4 mb-4'>
      {steps.map((step, index) => (
        <li key={step.id} className='flex-1'>
          <div
            className={`flex border-primary items-center font-medium px-2 py-3 w-full rounded-lg text-xs lg:text-sm transition-colors duration-300 ${
              index < stepIndex ? 'text-primary' : index === stepIndex ? 'text-gray-200' : 'text-gray-200'
            }`}
          >
            <span
              className={`w-6 h-6 flex justify-center items-center mr-2 text-xs rounded-full transition-colors duration-300 ${
                index < stepIndex ? 'text-white' : index === stepIndex ? 'text-gray-200' : 'text-gray-200'
              }`}
            >
              {String(index + 1).padStart(2, '0')}
            </span>
            <h4 className={`transition-colors duration-300 ${index <= stepIndex ? 'text-gray-200' : 'text-gray-200'}`}>
              {step.description}
            </h4>
          </div>
        </li>
      ))}
    </ol>
  )
}

const MainWrapperCashier: React.FC<MainWrapperProps> = ({ isOpen, onClose, children, currentStep }) => {
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center' style={{ zIndex: 2300 }}>
      <div className='relative bg-background border-2 border-primary rounded-lg shadow-glow-primary max-w-4xl p-4'>
        <div className='flex justify-between items-center mb-2'>
          <h2 className='text-xl font-bold text-primary'>Cashier</h2>
          <button
            onClick={onClose}
            className='text-primary border border-primary rounded-full w-6 h-6 flex items-center justify-center hover:bg-primary hover:text-white transition-colors duration-300'
          >
            X
          </button>
        </div>

        {/* <MultiStepIndicator currentStep={currentStep} /> */}

        <div className='overflow-auto max-h-[80vh] md:max-h-[85vh] text-sm'>{children}</div>
      </div>
    </div>
  )
}

export default MainWrapperCashier
