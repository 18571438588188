import { useCallback } from 'react'
import Swal from 'sweetalert2'
import './alertModal.scss'

interface UseAlertModalProps {
  title: string
  confirmButtonText: string
  denyButtonText?: string
  onConfirm?: () => void
  onDeny?: () => void
  showDenyButton?: boolean
  showCancelButton?: boolean
}

const useAlertModal = ({
  title,
  confirmButtonText,
  denyButtonText,
  onConfirm,
  onDeny,
  showDenyButton = false,
  showCancelButton = true,
}: UseAlertModalProps) => {
  const showAlert = useCallback(() => {
    const swalProps: UseAlertModalProps & any = {
      title,
      showDenyButton,
      showCancelButton,
      confirmButtonText,
      confirmButtonColor: '#c7a763',
      icon: 'warning',
    }

    if (denyButtonText) {
      swalProps.denyButtonText = denyButtonText
    }

    Swal.fire(swalProps).then((result) => {
      if (result.isConfirmed) {
        onConfirm && onConfirm()
      } else if (result.isDenied) {
        onDeny && onDeny()
      }
    })
  }, [title, showDenyButton, showCancelButton, confirmButtonText, denyButtonText, onConfirm, onDeny])

  return showAlert
}

export default useAlertModal
