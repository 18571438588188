import React from 'react'

export interface ExternalProps {
  open: boolean
  data: React.ReactNode
}

export interface ExternalActionProps {
  onClose: () => void
}

export const ContDialog: React.FC<ExternalProps & ExternalActionProps> = ({ open, data, onClose }) => {
  if (!open) return null

  return (
    <div className='fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center'>
      <div className='bg-background text-white rounded-lg shadow-xl w-full max-w-xs m-4'>
        <div className='border-b border-primary px-4 py-2'>
          <h3 className='text-lg text-primary'>Contribution Table</h3>
        </div>
        <div className='p-4 overflow-auto'>{data}</div>
        <div className='border-t border-primary px-4 py-2 flex justify-end'>
          <button onClick={onClose} className='bg-transparent border-2 border-primary p-2 rounded hover:opacity-50'>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default ContDialog
