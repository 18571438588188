// ToastContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react'
import Toast from './../shared/components/toast'

interface ToastMessage {
  id: number
  type: 'success' | 'info' | 'warning' | 'error'
  message: string
}

interface ToastContextType {
  addToast: (message: string, type?: ToastMessage['type']) => void
}

const ToastContext = createContext<ToastContextType | undefined>(undefined)

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastMessage[]>([])

  // Función para agregar un toast
  const addToast = (message: string, type: ToastMessage['type'] = 'info') => {
    const id = Date.now()
    setToasts((prev) => [...prev, { id, type, message }])

    setTimeout(() => {
      removeToast(id)
    }, 10000)
  }

  const removeToast = useCallback((id: number) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div style={{ zIndex: 2301 }} className='fixed top-50 right-15 space-y-2'>
        {toasts.map((toast) => (
          <Toast key={toast.id} type={toast.type} message={toast.message} onClose={() => removeToast(toast.id)} />
        ))}
      </div>
    </ToastContext.Provider>
  )
}

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}
