import React from 'react'

export interface ExternalProps {
  rows: any[]
}

export const WageringHistoryTable = (props: ExternalProps) => {
  const { rows } = props

  return (
    <div className='overflow-x-auto bg-gray-800 shadow-md rounded-lg'>
      <table className='min-w-full bg-gray-800'>
        <thead className='bg-gray-800'>
          <tr>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>Date</th>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>Ticket</th>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>Type</th>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>Description</th>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>Free Play</th>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>Risk</th>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>To Win</th>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>Result</th>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>
              Contribution %
            </th>
            <th className='py-3 px-6 text-left text-xs font-medium text-white uppercase tracking-wider'>
              Contribution Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {rows && rows.length > 0 ? (
            rows.map((row) => (
              <tr key={row.ticket} className='border-b hover:bg-gray-100'>
                <td className='py-4 px-6 text-sm text-gray-700'>
                  {new Date(row.timestamp).toLocaleString('en-US', {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  })}
                </td>
                <td className='py-4 px-6 text-sm text-gray-700'>{row.ticket}</td>
                <td className='py-4 px-6 text-sm text-gray-700'>{row.type}</td>
                <td className='py-4 px-6 text-sm text-gray-700'>{row.description}</td>
                <td className='py-4 px-6 text-sm text-gray-700'>{row.freePlay ? 'Yes' : 'No'}</td>
                <td className='py-4 px-6 text-sm text-gray-700'>{row.risk}</td>
                <td className='py-4 px-6 text-sm text-gray-700'>{row.toWin}</td>
                <td className='py-4 px-6 text-sm text-gray-700'>{row.result}</td>
                <td className='py-4 px-6 text-sm text-gray-700'>{row.cPercent}</td>
                <td className='py-4 px-6 text-sm text-gray-700'>${row.contribution}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10} className='py-4 px-6 text-center text-sm text-gray-500'>
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
