export enum CashierRoutes {
  COMPLETE = 'complete',
  PAYMENT = 'payment',
  CONFIRM = 'confirm',
  INFO = 'info',
  BONUS = 'bonus',
  BANK_SELECT = 'bank-select',
  METHOD = 'method',
  UPDATE_USER_PROFILE = 'update-user-profile',
  COGNITO_FLOW = 'cognito-flow',
  CASHIER = 'cashier',
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
  LOGIN = 'login',
  EXT_LOGIN = 'ext-login',
  EXT_AUTH = 'ext-auth',
  HOME = 'home',
  NOT_FOUND = 'not-found',
}
