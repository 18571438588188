import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { TransactionTypeEnum } from '../../../enum/transactions/TransactionTypeEnum'
import { PaymentMethodEnum } from '../../../enum/transactions/PaymentMethodEnum'
/* import { BonusAmountTypeEnum } from '../../../enum/bonus/bonus-type-amount-enum' */
import { IPaymentMethods } from '../../../models/paymentMethods'
import { useToast } from '../../../providers/ToastContext'
import { useTransaction } from '../../../providers/TransactionContext'
import useSWR from 'swr'
import { CashierRoutes } from '../../../enum/routes/routes-site-enum'
import { CircleAlert } from 'lucide-react'
import RangeSlider from '../../../shared/components/range-slider'

export interface TransactionInfoPageProps {
  setCurrentRoute: Dispatch<SetStateAction<CashierRoutes>>
  isLoading: boolean
  user: any
  /*   account: string
  accounts: string[] */
  /* amount: any */
  /* bonus: any */
}

export const GoToProfile = () => {
  // TODO FIX THIS
  // window.parent.postMessage('go_to_profile', '*')
}

export const TransactionInfoPage = (props: TransactionInfoPageProps) => {
  const { /* account, accounts, amount, bonus, */ setCurrentRoute, isLoading, user } = props

  const validNameReg = RegExp(/[a-zA-Z&]+[\s]+([a-zA-Z&]+[\s]?)+/)
  const hasNumberReg = RegExp(/\d/)
  const btcAddressReg = RegExp(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{24,63}$/)

  const [openAccountSelect, setOpenAccountSelect] = React.useState<boolean>(false)
  const [windowOpened, setWindowOpened] = useState<Window | null>(null)
  const [acceptConditions, setAcceptConditions] = useState(false)
  const [instantPayContinueProcess, setInstantPayContinueProcess] = useState<boolean>(true)
  const [instantPayAcceptUpdateInformation, setInstantPayAcceptUpdateInformation] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState(false)

  const { addToast } = useToast()
  const {
    transactionType,
    isWelcomeBonus,
    transactionMethod,
    setTransactionAmount,
    setTransactionBonus,
    setTransactionAccount,
    transactionAmount,
    transactionAccount,
    transactionBonus,
  } = useTransaction()

  const { data: depositAccounts } = useSWR(`/pcashier/deposit-accounts?payment_method_id=${transactionMethod?.id}`)

  useEffect(() => {
    if (depositAccounts?.[0] && transactionType === TransactionTypeEnum.DEPOSIT) {
      setTransactionAccount(depositAccounts?.[0])
    }
  }, [depositAccounts, setTransactionAccount, transactionType])

  console.log(depositAccounts)

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const claimedBonus = (bonus: any) => {
    return bonus != null
  }

  const getMinMax = (transactionType?: number, bonus?: any) => {
    if (bonus != null) {
      return {
        min: bonus.min_deposit,
        max: bonus.max_deposit,
      }
    }
    if (transactionType == TransactionTypeEnum.DEPOSIT) {
      return {
        min: transactionMethod?.deposit_min,
        max: transactionMethod?.deposit_max,
      }
    }
    return {
      min: transactionMethod?.withdrawal_min,
      max: transactionMethod?.withdrawal_max,
    }
  }

  const checkInfo = (
    transactionType: number,
    method: IPaymentMethods | null,
    amount: any,
    account: string | null,
    min: any,
    max: any,
  ) => {
    try {
      min = parseFloat(min)
      amount = parseFloat(amount)
      max = parseFloat(max)

      if (transactionType == TransactionTypeEnum.DEPOSIT) {
        console.log(account)
        if (account == null || (account != null && account.trim().length == 0)) {
          switch (transactionMethod?.id) {
            case PaymentMethodEnum.ZELLE:
              addToast('Please enter your account name.', 'error')
              return
            case PaymentMethodEnum.CASH_APP:
              addToast('Please enter your cash app tag.', 'error')
              return
            case PaymentMethodEnum.VENMO:
              addToast('Please enter your venmo account.', 'error')
              return
            default:
              break
          }
        }

        if (account) {
          switch (transactionMethod?.id) {
            case PaymentMethodEnum.ZELLE:
              if (!validNameReg.test(account) || hasNumberReg.test(account)) {
                setOpenModal(true)
                return
              }
              break
            default:
              if (
                transactionMethod?.id &&
                ![PaymentMethodEnum.ZELLE, PaymentMethodEnum.CASH_APP, PaymentMethodEnum.VENMO].includes(
                  transactionMethod?.id,
                )
              ) {
                setCurrentRoute(CashierRoutes.CONFIRM)
              }
              break
          }
        }
      }
      if (
        transactionMethod?.id &&
        transactionType == TransactionTypeEnum.WITHDRAWAL &&
        [
          PaymentMethodEnum.BITCOIN,
          PaymentMethodEnum.INSTANT_BITCOIN,
          PaymentMethodEnum.APPLE_PAY,
          PaymentMethodEnum.CASH_APP,
        ].indexOf(transactionMethod?.id) != -1 &&
        (!account == null || (account != null && account.trim().length == 0))
      ) {
        if (method?.id === PaymentMethodEnum.CASH_APP) {
          // 'Cash App'
          return addToast('Please enter your cash app tag.', 'error')
        } else if (method?.id === PaymentMethodEnum.APPLE_PAY) {
          // 'Apple Pay'
          return addToast('Please enter your apple pay phone or email.', 'error')
        } else if (
          [PaymentMethodEnum.BITCOIN, PaymentMethodEnum.INSTANT_BITCOIN].indexOf(transactionMethod?.id) != -1
        ) {
          // 'Bitcoin'
          return addToast('Please enter a Bitcoin address.', 'error')
        }
      } else if (!amount || amount == '' || amount == '0') {
        return addToast('Please enter an amount. Must be greater than $0.', 'error')
      } else if (amount < min || amount > max) {
        return addToast('Please enter an amount between $' + min + ' and $' + max + '.', 'error')
      } else if (
        transactionType == TransactionTypeEnum.WITHDRAWAL &&
        transactionMethod?.id &&
        [PaymentMethodEnum.BITCOIN, PaymentMethodEnum.INSTANT_BITCOIN].indexOf(transactionMethod?.id) != -1 &&
        (!account || (account && !btcAddressReg.test(account)))
      ) {
        /*         return addToast('Please enter a valid BTC address.', 'error')
      } else { */
        /*  cashierDialog.isShow
          ? setCashierDialogAction({ isShow: true, type: '/confirm', transactionType: type })
          : getHistory().push('/confirm') */
        setCurrentRoute(CashierRoutes.CONFIRM)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getMethodAccountLabel = (method_id?: number) => {
    switch (method_id) {
      case PaymentMethodEnum.BITCOIN:
      case PaymentMethodEnum.INSTANT_BITCOIN:
        return 'Bitcoin address'
        break
      case PaymentMethodEnum.CASH_APP:
        return 'Cash Tag'
        break
      case PaymentMethodEnum.VENMO:
        return 'Venmo Account'
        break
      case PaymentMethodEnum.APPLE_PAY:
        return 'Apple Pay Phone or Email'
        break
      case PaymentMethodEnum.ZELLE:
        if (transactionType == TransactionTypeEnum.WITHDRAWAL) return 'Zelle Email or Phone'
        else return 'Enter your first name + last name'
        break
    }
  }
  /* 
  const getMethodHelpText = (method: IPaymentMethods, transactionType: number) => {
    switch (transactionMethod?.id) {
      case PaymentMethodEnum.BITCOIN:
      case PaymentMethodEnum.INSTANT_BITCOIN:
        return (
          <small>
            Please enter <b>your Bitcoin Address</b>.
          </small>
        )
        break
      case PaymentMethodEnum.CASH_APP:
        return (
          <small>
            Please enter <b>your cash app tag</b> you will be{' '}
            {transactionType == TransactionTypeEnum.WITHDRAWAL ? 'receiving' : 'sending'} from.
          </small>
        )
      case PaymentMethodEnum.VENMO:
        return (
          <small>
            Please enter <b>your venmo account</b> you will be sending from.
          </small>
        )
        break
      case PaymentMethodEnum.APPLE_PAY:
        return (
          <small>
            Please enter <b>Apple Pay Phone or Email</b> you will be sending from.
          </small>
        )
        break
    }
  } */

  const getMethodAccountPlaceholder = (method_id?: number) => {
    switch (method_id) {
      case PaymentMethodEnum.BITCOIN:
      case PaymentMethodEnum.INSTANT_BITCOIN:
        return 'Bitcoin Address'
      case PaymentMethodEnum.CASH_APP:
        return '$CashTag'
      case PaymentMethodEnum.VENMO:
        return '@VenmoAccount'
      case PaymentMethodEnum.APPLE_PAY:
        return 'Apple Pay Phone or Email'
      case PaymentMethodEnum.ZELLE:
        if (transactionType == TransactionTypeEnum.WITHDRAWAL) return 'Zelle Email or Phone'
        else return 'Enter your first name + last name'
    }
  }

  const selectCustomAmount = (amount: any) => {
    setTransactionAmount(amount)
  }

  const handleInstantPayCheck = () => {
    localStorage.setItem('instant_pay_accept_tos', 'success')
    setInstantPayContinueProcess(true)
    setInstantPayAcceptUpdateInformation(true)
  }

  const handleOpenWindow = () => {
    const new_window = window.open('https://app.checkbook.io/account/settings/accounts', 'check_box')
    if (new_window) {
      new_window.focus()
      setWindowOpened(new_window)
    }
  }

  useEffect(() => {
    if (transactionType === TransactionTypeEnum.WITHDRAWAL) {
      setTransactionAccount('')
    }
  }, [])

  // return <>testing</>

  return (
    <React.Fragment>
      {transactionMethod && (
        <div className='block px-2 mx-auto max-w-md border-b border-primary'>
          <fieldset>
            <legend className='text-center text-lg text-primary mb-4'>
              Enter {transactionType === TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'} Info
            </legend>
          </fieldset>
          {transactionType === TransactionTypeEnum.DEPOSIT &&
            (transactionMethod?.id === PaymentMethodEnum.BITCOIN ||
              transactionMethod?.id === PaymentMethodEnum.INSTANT_BITCOIN) && (
              <div className='bg-gray-900 border-l-4 border-yellow-500 text-yellow-600 p-4 mb-4 text-center'>
                <p className='text-sm mb-2'>
                  When sending Bitcoin, please makes sure you select the default/standard fee that your wallet selects.
                </p>
                <p className='text-sm font-bold'>Sending Bitcoin at a lower processing fee will delay your deposit.</p>
              </div>
            )}
        </div>
      )}

      {transactionMethod?.id === PaymentMethodEnum.INSTANT_PAY && !instantPayContinueProcess && (
        <>
          <div>
            <div className='bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 text-center'>
              <p className='text-sm mb-2'>
                Before submitting your withdrawal, please click the following link to ensure your card info is up to
                date:
              </p>
              <p className='text-blue-500 cursor-pointer text-sm underline' onClick={handleOpenWindow}>
                https://app.checkbook.io/account/settings/accounts
              </p>
            </div>
            {windowOpened && (
              <div className='flex items-center mb-4'>
                <input
                  type='checkbox'
                  className='mr-2 accent-yellow-600'
                  checked={instantPayAcceptUpdateInformation}
                  onChange={(event) => setInstantPayAcceptUpdateInformation(event.target.checked)}
                />
                <p className='text-sm'>I have updated my checkbook account details and these are correct.</p>
              </div>
            )}
          </div>
          <div className='flex justify-between mt-4'>
            <button
              className='bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded'
              onClick={() => {
                setTransactionBonus(null)
                setCurrentRoute(CashierRoutes.METHOD)
              }}
            >
              Back
            </button>
            {windowOpened && (
              <button
                className={`bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded ${
                  !instantPayAcceptUpdateInformation && 'opacity-50 cursor-not-allowed'
                }`}
                onClick={handleInstantPayCheck}
                disabled={!instantPayAcceptUpdateInformation}
              >
                Continue
              </button>
            )}
          </div>
        </>
      )}

      {transactionMethod?.id === PaymentMethodEnum.INSTANT_PAY &&
        instantPayContinueProcess === true &&
        user &&
        user.site === 'VIPCLUB' && (
          <div className='bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4'>
            <p className='text-sm'>
              This eCheck will be under your name{' '}
              <b>
                <u>
                  {user.first_name} {user.last_name}
                </u>
              </b>{' '}
              and be sent to{' '}
              <b>
                <u>{user.email}</u>
              </b>
              . <br />
              The debit card you use with the Instant Pay check{' '}
              <b>
                <u>MUST</u>
              </b>{' '}
              match the name above.
              <br />
              <b>If this information is incorrect, please update your profile.</b>
              <br />
            </p>
            <button
              className='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 mt-2 rounded block mx-auto'
              onClick={() => {
                GoToProfile()
              }}
            >
              Update Profile
            </button>
          </div>
        )}

      {(transactionMethod?.id !== PaymentMethodEnum.INSTANT_PAY ||
        (transactionMethod?.id === PaymentMethodEnum.INSTANT_PAY && instantPayContinueProcess === true)) && (
        <>
          <div className='w-full px-4'>
            <div className='bg-background/10 shadow-md rounded-lg p-6 mb-4'>
              {transactionType === TransactionTypeEnum.DEPOSIT &&
                transactionMethod?.id &&
                transactionMethod?.id !== PaymentMethodEnum.BONUS &&
                ![
                  PaymentMethodEnum.DEBIT_CARD,
                  PaymentMethodEnum.APPLE_PAY,
                  PaymentMethodEnum.INSTANT_APPLE_PAY,
                  'BITCOIN',
                  PaymentMethodEnum.INSTANT_BITCOIN,
                ].includes(transactionMethod?.id) && (
                  <div className='bg-gray-800 border-l-4 border-yellow-500 text-yellow-500 p-4 mb-4'>
                    <p className='text-xs'>
                      <CircleAlert className='inline mr-2 mb-2' />
                      Any transactions with{' '}
                      <b>
                        <u>NOTES OR USERNAMES RELATED TO GAMBLING OR BETTING</u>
                      </b>{' '}
                      will automatically be returned, and you will be blocked from the platform.
                    </p>
                  </div>
                )}
              <RangeSlider
                amount={transactionAmount}
                setAmount={selectCustomAmount}
                maxAmount={500}
                /* todo FIX */ divisions={20}
              />
              <div className='tour_deposit-customAmount text-center'>
                <div className='flex justify-center flex-wrap gap-4'>
                  {transactionMethod?.id &&
                    [
                      PaymentMethodEnum.APPLE_PAY,
                      PaymentMethodEnum.INSTANT_APPLE_PAY,
                      PaymentMethodEnum.INSTANT_DEPOSIT,
                      PaymentMethodEnum.DEBIT_CARD,
                    ].includes(transactionMethod?.id) && (
                      <button
                        className='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded'
                        onClick={() => selectCustomAmount(50)}
                      >
                        $50
                      </button>
                    )}
                  <button
                    className='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded'
                    onClick={() => selectCustomAmount(100)}
                  >
                    $100
                  </button>
                  {transactionMethod?.id &&
                    [
                      PaymentMethodEnum.APPLE_PAY,
                      PaymentMethodEnum.INSTANT_APPLE_PAY,
                      PaymentMethodEnum.INSTANT_DEPOSIT,
                      PaymentMethodEnum.DEBIT_CARD,
                    ].includes(transactionMethod?.id) && (
                      <button
                        className='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded'
                        onClick={() => selectCustomAmount(200)}
                      >
                        $200
                      </button>
                    )}
                  <button
                    className='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded'
                    onClick={() => selectCustomAmount(250)}
                  >
                    $250
                  </button>
                  {transactionMethod?.id !== PaymentMethodEnum.INSTANT_DEPOSIT && (
                    <button
                      className='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded'
                      onClick={() => selectCustomAmount(500)}
                    >
                      $500
                    </button>
                  )}
                </div>
              </div>
              <div className='tour_deposit-amount mt-4 w-full max-w-md mx-auto'>
                <input
                  type='number'
                  className='border border-gray-300 rounded px-4 py-2 w-full mb-4 !text-gray-900'
                  placeholder={isWelcomeBonus ? 'Welcome Bonus Amount' : 'Amount ($USD)'}
                  value={transactionAmount === 0 ? '' : transactionAmount}
                  onChange={(e) => setTransactionAmount(Number(e.target.value))}
                  disabled={transactionMethod?.id === PaymentMethodEnum.BONUS}
                />
              </div>
              <small className='block mb-4 text-gray-200 text-center'>
                {claimedBonus(transactionBonus) && (
                  <p>
                    <b className='text-primary'>
                      Claimed [{transactionBonus.code}]{' '}
                      {transactionBonus.description === 'NO rollover, risk free bet.' ? (
                        'Risk-Free Bet'
                      ) : (
                        <>
                          {transactionBonus.type_amount_id === 'FIXED'
                            ? `$${transactionBonus.match_multiplier * 100} `
                            : `${transactionBonus.match_multiplier * 100}% `}
                          {transactionBonus.type} Bonus w/ {transactionBonus.rollover_multiplier * 1}x Rollover
                        </>
                      )}
                    </b>
                  </p>
                )}
                Fee: {transactionMethod?.fee !== null && transactionMethod?.fee}% | Min: $
                {Intl.NumberFormat().format(getMinMax(transactionType, transactionBonus).min)} / Max: $
                {Intl.NumberFormat().format(getMinMax(transactionType, transactionBonus).max)}
                <br />
                {transactionBonus &&
                  transactionBonus.id === 93 &&
                  'We recommend you wager your full deposit to maximize on this bonus.'}
              </small>
              {(transactionType === TransactionTypeEnum.WITHDRAWAL ||
                (transactionMethod?.id &&
                  [
                    PaymentMethodEnum.CASH_APP,
                    PaymentMethodEnum.APPLE_PAY,
                    PaymentMethodEnum.ZELLE,
                    PaymentMethodEnum.VENMO,
                  ].includes(transactionMethod?.id))) &&
                transactionMethod?.id !== PaymentMethodEnum.INSTANT_PAY && (
                  <div>
                    <input
                      type='text'
                      className='border border-primary bg-transparent rounded px-4 py-2 w-full mb-4'
                      placeholder={getMethodAccountPlaceholder(transactionMethod?.id)}
                      value={transactionAccount ?? ''}
                      onChange={(e) => setTransactionAccount(e.target.value)}
                    />
                    {transactionType === TransactionTypeEnum.WITHDRAWAL &&
                      transactionMethod?.id === PaymentMethodEnum.INSTANT_BITCOIN && (
                        <div className='text-left mt-4'>
                          <div className='bg-gray-700 border-l-4 border-yellow-500 text-yellow-500 p-4 mb-4'>
                            <p>
                              <CircleAlert className='inline mr-1 mb-2' />
                              <strong>Attention</strong>! <br />
                              Bitcoin Withdrawals are irreversible. We cannot be held responsible for wrong addresses
                              entered. PLEASE double check the address before hitting submit. If an incorrect address is
                              entered, your funds cannot be retrieved. Thank You!
                              <br />
                              <br />
                              <small>VIP Club Team.</small>
                              {transactionAmount && transactionAmount > 500 && (
                                <p className='text-sm text-red-500 mt-2'>
                                  <CircleAlert className='inline mr-1 mb-2' /> If your withdrawal is above $500, please
                                  note you will receive TWO SEPARATE transactions to the same address you provided, one
                                  for $500 and the remaining will be sent a few minutes later.
                                </p>
                              )}
                            </p>
                          </div>
                          <div className='flex items-center'>
                            <input
                              type='checkbox'
                              className='mr-2 w-6 h-6 accent-primary'
                              checked={acceptConditions}
                              onChange={(event) => setAcceptConditions(event.target.checked)}
                            />
                            <span>I accept terms and conditions.</span>
                          </div>
                        </div>
                      )}
                  </div>
                )}
            </div>
          </div>
          <div className='hidden sm:flex justify-between mt-4'>
            <button
              className='bg-transparent border-2 border-primary hover:bg-primary text-white py-2 px-4 rounded'
              onClick={() => {
                setTransactionBonus(null)
                setCurrentRoute(CashierRoutes.METHOD)
              }}
            >
              Back
            </button>
            <button
              className={`bg-primary border-2 border-primary hover:opacity-50  text-white py-2 px-4 rounded ${
                !acceptConditions &&
                transactionType === TransactionTypeEnum.WITHDRAWAL &&
                'opacity-50 cursor-not-allowed'
              }`}
              onClick={() =>
                checkInfo(
                  transactionType,
                  transactionMethod,
                  transactionAmount,
                  transactionAccount,
                  getMinMax(transactionType, transactionBonus).min,
                  getMinMax(transactionType, transactionBonus).max,
                )
              }
              /*               disabled={
                transactionType === TransactionTypeEnum.WITHDRAWAL &&
                transactionMethod?.id === PaymentMethodEnum.INSTANT_BITCOIN &&
                !acceptConditions
              } */
            >
              Continue {transactionType === TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}
            </button>
          </div>
          <div className='flex sm:hidden flex-col mt-4 space-y-4'>
            <button
              className='bg-transparent border-2 border-primary hover:opacity-50t text-white font-bold py-2 px-4 rounded'
              onClick={() => {
                setTransactionBonus(null)
                setCurrentRoute(CashierRoutes.METHOD)
              }}
            >
              Back
            </button>
            <button
              className={`bg-primary hover:opacity-50 text-white font-bold py-2 px-4 rounded ${
                !acceptConditions &&
                transactionType === TransactionTypeEnum.WITHDRAWAL &&
                'opacity-50 cursor-not-allowed'
              }`}
              onClick={() =>
                checkInfo(
                  transactionType,
                  transactionMethod,
                  transactionAmount,
                  transactionAccount,
                  getMinMax(transactionType, transactionBonus).min,
                  getMinMax(transactionType, transactionBonus).max,
                )
              }
              /*               disabled={
                transactionType === TransactionTypeEnum.WITHDRAWAL &&
                transactionMethod?.id === PaymentMethodEnum.INSTANT_BITCOIN &&
                !acceptConditions
              } */
            >
              Continue {transactionType === TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}
            </button>
          </div>
        </>
      )}

      {openModal && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div
            className='bg-gray-800 text-white rounded-lg p-6 max-w-sm w-full'
            style={{ transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}
          >
            <div className='bg-blue-100 text-blue-700 border-l-4 border-blue-500 p-4 rounded mb-4 text-base'>
              <p className='font-semibold'>Alert</p>
              Please, ONLY use first and last name of the sender&apos;s account (e.g Dylan Smith).
              <strong> Phone numbers or emails are not allowed</strong>
              <div className='flex justify-end mt-4'>
                <button
                  className='bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded'
                  onClick={handleModalClose}
                >
                  Agreed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {openAccountSelect && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div className='bg-white rounded-lg shadow-lg w-full max-w-lg'>
            <div className='p-4 border-b text-center text-lg font-semibold'>
              Set your {getMethodAccountLabel(transactionMethod?.id)}
            </div>
            <div className='p-4'>
              {isLoading && (
                <div className='text-center my-4'>
                  <div className='animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-gray-900'></div>
                </div>
              )}
              {!isLoading && (
                <div className='hidden sm:block'>
                  <ul className='max-h-64 overflow-y-auto'>
                    {depositAccounts &&
                      depositAccounts.length > 0 &&
                      depositAccounts.map((account: string, index: number) => (
                        <li key={`acc${index}`} className='flex justify-between items-center border-b py-2'>
                          <span>{account}</span>
                          <button
                            className='bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded'
                            onClick={() => {
                              setTransactionAccount(account)
                              setOpenAccountSelect(false)
                            }}
                          >
                            Select
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              {!isLoading && (
                <div className='block sm:hidden'>
                  <div className='grid grid-cols-1 gap-2'>
                    {depositAccounts &&
                      depositAccounts.length > 0 &&
                      depositAccounts.map((account: string, index: number) => (
                        <div key={`acc${index}`} className='flex justify-between items-center border-b py-2'>
                          <span>{account}</span>
                          <button
                            className='bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded'
                            onClick={() => {
                              setTransactionAccount(account)
                              setOpenAccountSelect(false)
                            }}
                          >
                            Select
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
            <div className='p-4 border-t flex justify-end'>
              <button
                className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
                onClick={() => setOpenAccountSelect(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
