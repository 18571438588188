import { API_PATH } from '../../constants'
import { IInsertTransaction } from '../../models/player'
import { IInsertTransactionResponse } from '../../models/Transactions/transaction'
import { vipApi } from '../../utils/vip-api'

type insertTransactionResponse = {
  data: IInsertTransactionResponse
}

export const insertTransaction = async (data: IInsertTransaction): Promise<insertTransactionResponse> => {
  return await vipApi(API_PATH.CASHIER.INSERT_TRANSACTION, 'post', data, 'json')
}
