import React, { Dispatch, SetStateAction } from 'react'

interface RangeSliderProps {
  maxAmount: number
  divisions: number
  amount: number
  setAmount: Dispatch<SetStateAction<number>>
}

const RangeSlider: React.FC<RangeSliderProps> = ({ amount = 100, divisions = 10, setAmount, maxAmount }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Math.abs(Number(event.target.value)))
  }

  const step = 10

  return (
    <div className='w-full max-w-xl mx-auto p-4'>
      <label className='flex justify-between mb-4 text-sm font-medium text-white'>
        <span>Select an amount or enter a custom amount below:</span>
        <span>{amount}</span>
      </label>
      <input
        type='range'
        min={0}
        max={maxAmount}
        step={step}
        value={amount}
        onChange={handleChange}
        className='w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer accent-yellow-600'
      />
      <div className='flex justify-between mt-2'>
        <span className='text-xs text-gray-500'>0</span>
        <span className='text-xs text-gray-500'>{maxAmount}</span>
      </div>
    </div>
  )
}

export default RangeSlider
