import React from 'react'

interface Row {
  id: string | number
  name: string
  category?: {
    name: string
  }
  value?: {
    percentage: string | number
  }
}

export interface ExternalProps {
  rows: Row[]
}

export const ContTable: React.FC<ExternalProps> = ({ rows }) => {
  return (
    <div className='overflow-x-auto bg-gray-900 text-white shadow-md rounded-lg'>
      <table className='min-w-full table-auto'>
        <thead className='bg-gray-700 text-white'>
          <tr>
            <th className='px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider'>Wager Type</th>
            <th className='px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider'>
              Contribution %
            </th>
          </tr>
        </thead>
        <tbody className='bg-gray-800 divide-y divide-gray-200'>
          {rows && rows.length > 0 ? (
            rows.map((row) => (
              <tr key={row.id} className='hover:bg-primary'>
                <td className='px-4 py-2 whitespace-nowrap'>
                  <div className='text-sm font-medium text-gray-900'>{row.name}</div>
                  {row.category && <div className='text-sm text-gray-500'>{row.category.name}</div>}
                </td>
                <td className='px-4 py-2 whitespace-nowrap text-sm text-gray-500'>{row.value?.percentage}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2} className='px-4 py-2 text-center text-sm text-gray-500'>
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ContTable
