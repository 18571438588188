import React from 'react'

interface ButtonProps {
  color: 'primary' | 'secondary'
  className?: string
  onClick: () => void
  type?: 'button' | 'submit' | 'reset'
  label: string
  isLoading?: boolean
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({
  color = 'primary',
  className = '',
  onClick,
  type = 'button',
  label,
  isLoading = false,
  disabled = false,
}) => {
  const colorClasses = {
    primary: 'bg-primary text-black hover:bg-primary/90',
    secondary: 'bg-gray-600 text-white hover:bg-gray-700',
  }

  const baseClasses = `w-full ${colorClasses[color]} focus:ring-4 focus:outline-none focus:ring-opacity-50 font-medium rounded-lg text-sm px-5 py-2.5 text-center`
  const disabledClasses = 'opacity-50 cursor-not-allowed'

  return (
    <button
      type={type}
      className={`${baseClasses} ${isLoading || disabled ? disabledClasses : ''} ${className}`}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <div className='flex items-center justify-center'>
          <div className='animate-spin rounded-full h-5 w-5 border-b-2 border-black mr-2'></div>
          Loading...
        </div>
      ) : (
        label
      )}
    </button>
  )
}

export default Button
