import * as React from 'react'
import axios from 'axios'
import { SWRConfig } from 'swr'
import { BareFetcher } from 'swr'

export interface ProvidersProps {
  children: React.ReactNode
}

const fetcher = async (url: string, token: string | null): Promise<any> => {
  // FIX
  const BASE_URL = 'https://api-vip-sandbox.pdub.io/api'

  // eslint-disable-next-line
  try {
    const response = await axios.get(`${BASE_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data?.data
  } catch (error) {
    throw error
  }
}

const authFetcher: BareFetcher<any> = (url: string) => {
  const token = String(localStorage.getItem('token'))

  if (!token) {
    return Promise.reject(new Error('No token found'))
  }

  return fetcher(url, token)
}

function Providers({ children }: ProvidersProps) {
  return (
    <SWRConfig
      value={{
        fetcher: authFetcher,
      }}
    >
      {children}
    </SWRConfig>
  )
}

export default Providers
