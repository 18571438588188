export const emailBaseList = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'msn.com',
  'proton.me',
  'outlook.com',
  'icloud.com',
  'me.com',
  'mail.com',
  'live.com',
  'inbox.com',
  'zoho.com',
  'gmx.com',
  'ymail.com',
  'mailfence.com',
  'hushmail.com',
  'fastmail.com',
  'tutanota.com',
  'earthlink.net',
  'comcast.net',
  'verizon.net',
  'att.net',
  'sbcglobal.net',
  'cox.net',
  'charter.net',
  'frontier.com',
  'optimum.net',
  'centurylink.net',
  'bellsouth.net',
]
