import React, { Dispatch, SetStateAction } from 'react'
import {
  IApprovePurchaseDto,
  IApprovePurchaseRequestDto,
  EmptyIApprovePurchaseRequestDto,
  IApprovePurchaseIdDto,
} from '../../../../models/ApprovePurchase/ApprovePurchase'
import { formatUSDNumber } from '../../../../shared/functions/common'
import { FileText } from 'lucide-react'
import { CashierRoutes } from '../../../../enum/routes/routes-site-enum'
import { approvePurchase } from '../../../../api/cashier/approve-purchase'

export interface ApprovePurchaseStepProps {
  setCurrentRoute: Dispatch<SetStateAction<CashierRoutes>>
  transaction_approve_purchase: IApprovePurchaseDto[] | null
}

export const ApprovePurchaseStep = (props: ApprovePurchaseStepProps) => {
  const { transaction_approve_purchase, setCurrentRoute } = props
  const [approvePurchaseRequest, setApprovePurchaseRequest] = React.useState<IApprovePurchaseRequestDto>(
    EmptyIApprovePurchaseRequestDto,
  )
  const validNameReg = RegExp(/^[A-Za-z]+( [A-Za-z]+)+$/)

  const AcceptTermsAndConditions = () => {
    const signature = (approvePurchaseRequest.signature ?? '').trim()
    if (validNameReg.test(signature)) {
      const localTransactions: IApprovePurchaseIdDto[] = []

      transaction_approve_purchase &&
        transaction_approve_purchase.forEach((item: IApprovePurchaseDto) => {
          localTransactions.push({ id: item.id })
        })

      approvePurchase({
        signature: signature,
        transactions: localTransactions,
      })
    } else {
      alert('Please fill in full name (e.g. Dylan Smith).')
    }
  }

  return (
    <div className='container mx-auto p-6 mb-12 max-w-4xl bg-white rounded-lg shadow-lg'>
      {transaction_approve_purchase && (
        <>
          <legend className='text-xl font-bold text-center border-b border-gray-400 pb-4 mb-4'>Approve Purchase</legend>
          <div className='grid grid-cols-1 gap-4 mb-6'>
            <p className='text-justify leading-7'>
              By signing the form below, you fully acknowledge that the following transactions have been successfully
              fulfilled by VIP and you are fully satisfied with your purchases.
            </p>
            <div className='overflow-x-auto max-h-96 mb-4 border border-gray-200 rounded-lg'>
              <table className='w-full text-sm text-left text-gray-700 bg-white'>
                <thead className='text-xs uppercase bg-gray-50 text-gray-700'>
                  <tr>
                    <th className='py-2 px-4'>Transaction #</th>
                    <th className='py-2 px-4'>Transaction Date</th>
                    <th className='py-2 px-4'>Payment Method</th>
                    <th className='py-2 px-4'>Amount</th>
                    <th className='py-2 px-4'>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {transaction_approve_purchase &&
                    transaction_approve_purchase.length > 0 &&
                    transaction_approve_purchase.map((row: IApprovePurchaseDto) => (
                      <tr key={`approve_purchase_step_table_row_${row.id}`} className='border-b'>
                        <td className='py-2 px-4'>{row.id}</td>
                        <td className='py-2 px-4'>
                          {new Date(row.created_at).toLocaleString('en-US', {
                            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                          })}
                        </td>
                        <td className='py-2 px-4'>{row.payment_method.name}</td>
                        <td className='py-2 px-4'>{formatUSDNumber(row.amount)}</td>
                        <td className='py-2 px-4'>
                          {row.stripe_checkout_session && row.stripe_checkout_session.invoice_url && (
                            <button
                              onClick={() => {
                                if (row.stripe_checkout_session && row.stripe_checkout_session.invoice_url)
                                  window.open(row.stripe_checkout_session.invoice_url, '_blank')
                              }}
                              className='text-blue-500 hover:text-blue-700'
                            >
                              <FileText className='w-5 h-5 text-gray-700' />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <p className='text-justify leading-7'>
              This confirmation is essential to ensure your satisfaction as a VIP Customer. We appreciate your trust!
            </p>
            <div className='mb-4'>
              <label htmlFor='fullName' className='block text-sm font-medium text-gray-700 mb-2'>
                Your Full Name
              </label>
              <input
                id='fullName'
                type='text'
                value={approvePurchaseRequest.signature}
                onChange={(e) => setApprovePurchaseRequest({ ...approvePurchaseRequest, signature: e.target.value })}
                className='block w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500'
                placeholder='e.g. John Doe'
              />
            </div>
            <div className='flex justify-between'>
              <button
                className='px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400'
                onClick={() => {
                  setCurrentRoute(CashierRoutes.CASHIER)
                }}
              >
                Back
              </button>
              <button
                className='px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600'
                onClick={AcceptTermsAndConditions}
              >
                Accept and Confirm
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
