// Toast.tsx
import React from 'react'

interface ToastProps {
  type: 'success' | 'info' | 'warning' | 'error'
  message: string
  onClose: () => void
}

const toastColors: Record<string, string> = {
  success: 'bg-green-500',
  info: 'bg-blue-500',
  warning: 'bg-yellow-500',
  error: 'bg-red-500',
}

const Toast: React.FC<ToastProps> = ({ type, message, onClose }) => {
  return (
    <div
      className={`flex items-center w-full max-w-sm mx-auto overflow-hidden rounded-lg shadow-md ${toastColors[type]} fixed top-72 right-15`}
      style={{ zIndex: 2301 }}
    >
      <div className='w-2 bg-white'></div>
      <div className='flex items-center justify-between w-full px-4 py-2'>
        <div className='mx-3'>
          <p className='text-white font-semibold'>{message}</p>
        </div>
        <button onClick={onClose} className='text-white hover:text-gray-200 focus:outline-none'>
          &times;
        </button>
      </div>
    </div>
  )
}

export default Toast
