import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import useSWR from 'swr'
import { motion, AnimatePresence } from 'framer-motion'

import { TransactionMethodPage } from './views/cashier/transaction-method-page/transaction-method-page.view'
import { BonusSelectPage } from './views/cashier/bonus-select-page/bonus-select-page.view'
import { TransactionInfoPage } from './views/cashier/transaction-info-page/transaction-info-page.view'
import { CompletePage } from './views/cashier/complete-page/complete-page.view'
// import { RequestReceivedPageContainer } from './area/cashier/request-received-page/request-received-page.container'
// import { PlayerBankSelectPageContainer } from './area/cashier/player-bank-select-page/player-bank-select-page.container'
import { CashierPage } from './views/cashier/cashier-home-page/cashier.view'
import { ConfirmTransactionPage } from './views/cashier/confirm-transaction-page/confirm-transaction-page.view'

import { API_PATH } from './constants'
import { CashierRoutes } from './enum/routes/routes-site-enum'
import MainWrapperCashier from './shared/components/main-wrapper-cashier'
import { IPaymentMethods } from './models/paymentMethods'
import { useTransaction } from './providers/TransactionContext'

type CashierSubRoutesTypeProps = {
  setCurrentRoute: React.Dispatch<React.SetStateAction<CashierRoutes>>
  user: any
  rollover: any
  userBalance: any
  transactionLimitDetails: any
  depositMethods: IPaymentMethods[] | undefined
  withdrawalMethods: IPaymentMethods[] | undefined
}

// Objeto que mapea rutas con componentes
const cashierSubRoutes = ({
  setCurrentRoute,
  user,
  rollover,
  userBalance,
  transactionLimitDetails,
  depositMethods,
  withdrawalMethods,
}: CashierSubRoutesTypeProps): Partial<Record<CashierRoutes, any>> => ({
  /*
  [CashierRoutes.BANK_SELECT]: <PlayerBankSelectPageContainer setCurrentRoute={props.setCurrentRoute} />, */
  [CashierRoutes.CASHIER]: (
    <CashierPage
      setCurrentRoute={setCurrentRoute}
      user={user}
      rollover={rollover}
      userBalances={userBalance}
      transactionLimitDetails={transactionLimitDetails}
      isLoading={false}
    />
  ),
  [CashierRoutes.METHOD]: (
    <TransactionMethodPage
      setCurrentRoute={setCurrentRoute}
      user={user}
      isLoading={false}
      depositMethods={depositMethods}
      withdrawalMethods={withdrawalMethods}
      transaction_approve_purchase={null}
      is_approve_purchase_success={true}
    />
  ),
  [CashierRoutes.BONUS]: (
    <BonusSelectPage
      setCurrentRoute={setCurrentRoute}
      bonus={{}}
      availableBonuses={{}}
      isLoading={false}
      rollover={rollover}
      user={user}
    />
  ),
  [CashierRoutes.INFO]: <TransactionInfoPage setCurrentRoute={setCurrentRoute} user={user} isLoading={false} />,
  [CashierRoutes.CONFIRM]: <ConfirmTransactionPage setCurrentRoute={setCurrentRoute} user={user} isLoading={false} />,

  [CashierRoutes.COMPLETE]: <CompletePage setCurrentRoute={setCurrentRoute} /> /* 
  [CashierRoutes.PAYMENT]: <RequestReceivedPageContainer setCurrentRoute={props.setCurrentRoute} />, */,
})

type CashierContainerProps = {
  isCashierVisible: boolean
  setIsCashierOpen: Dispatch<SetStateAction<boolean>>
}

const CashierContainer: React.FC<CashierContainerProps> = ({ isCashierVisible = false, setIsCashierOpen }) => {
  const { resetTransaction } = useTransaction()
  const { data: user } = useSWR(API_PATH.USER.GET_USER_INFO)
  const { data: rollover } = useSWR(API_PATH.CASHIER.GET_ROLLOVERS)
  const { data: transactionLimitDetails } = useSWR(API_PATH.CASHIER.GET_TRANSACTION_LIMIT)
  const userBalance = {}
  const paramDeposit = `?player_number=${user?.username}&site=1&type=1`
  const { data: depositMethods } = useSWR<IPaymentMethods[] | undefined>(
    user?.username ? API_PATH.CASHIER.GET_METHODS(paramDeposit) : null,
  )
  const paramWithdrawal = `?player_number=${user?.username}&site=1&type=2`
  const { data: withdrawalMethods } = useSWR<IPaymentMethods[] | undefined>(
    user?.username ? API_PATH.CASHIER.GET_METHODS(paramWithdrawal) : null,
  )

  const [currentRoute, setCurrentRoute] = useState<CashierRoutes>(CashierRoutes.CASHIER)

  const routes = cashierSubRoutes({
    setCurrentRoute,
    user,
    rollover,
    userBalance,
    transactionLimitDetails,
    depositMethods,
    withdrawalMethods,
  })

  useEffect(() => {
    setCurrentRoute(CashierRoutes.CASHIER)
    resetTransaction()
    /* eslint-disable-next-line */
  }, [isCashierVisible])

  if (!isCashierVisible) {
    return null
  }

  return (
    <MainWrapperCashier isOpen={isCashierVisible} onClose={() => setIsCashierOpen(false)} currentStep={currentRoute}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={currentRoute}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.15 }}
        >
          {routes[currentRoute]}
        </motion.div>
      </AnimatePresence>
    </MainWrapperCashier>
  )
}

export default CashierContainer
