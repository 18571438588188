export interface IPlayerTransactionLimitProfile {
  id: number
  name: string
  limits: IPlayerTransactionLimit[]
}

export interface IPlayerTransactionLimit {
  id: number
  profileId: number
  transactionType: number
  player_transaction_limit_type_id: number
  quantity: number
  amount: number
}

export enum LimitTypeEnum {
  Hourly = 1,
  Daily = 2,
  Weekly = 3,
}
