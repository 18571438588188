import React, { ChangeEvent, useState, forwardRef } from 'react'
import { Eye, EyeOff } from 'lucide-react'

interface InputProps {
  label: string
  placeholder: string
  variant: 'bordered'
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  type?: string
  width?: string
  error?: boolean
  required?: boolean
  id?: string
}

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      placeholder,
      variant,
      value,
      onChange,
      onBlur,
      type = 'text',
      width = '100%',
      error = false,
      required = false,
      id = 'input',
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false)

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword)
    }

    const isPassword = type === 'password'

    return (
      <div className='mb-4 inputContainer' style={{ width }}>
        <label className='block text-white-700 text-sm font-light my-2' htmlFor='input'>
          {label}
          {!required && (
            <>
              <span className='text-red-500'> - </span>
              <span className='italic text-xs'>Optional</span>
            </>
          )}
        </label>
        <div className='relative'>
          <input
            type={isPassword && showPassword ? 'text' : type}
            name='input'
            id={id}
            className={`
              bg-white border text-gray-900 sm:text-sm rounded-lg 
              block w-full p-1.5
              focus:outline-none focus:ring-2 focus:ring-[#c8a964] focus:border-[#c8a964]
              ${variant === 'bordered' ? 'bordered' : ''}
              ${error ? 'border-red-500' : 'border-gray-300'}
              ${isPassword ? 'pr-10' : ''}
            `}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            ref={ref}
          />
          {isPassword && (
            <button
              type='button'
              onClick={togglePasswordVisibility}
              className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5'
            >
              {showPassword ? <EyeOff className='h-5 w-5 text-gray-500' /> : <Eye className='h-5 w-5 text-gray-500' />}
            </button>
          )}
        </div>
      </div>
    )
  },
)

export default PasswordInput
