import React, { Dispatch, SetStateAction, useState } from 'react'

import { FeeFreeTime } from '../../../models/FeeFreeTime'

import { TransactionTypeEnum } from '../../../enum/transactions/TransactionTypeEnum'
import { PaymentMethodEnum } from '../../../enum/transactions/PaymentMethodEnum'
import { IPaymentMethods } from '../../../models/paymentMethods'
import { SiteIDEnum } from '../../../enum/site/site-id-enum'

import { IApprovePurchaseDto } from '../../../models/ApprovePurchase/ApprovePurchase'
import { ApprovePurchaseStep } from './components/approve-purchase-step.component'
import { CashierRoutes } from '../../../enum/routes/routes-site-enum'
import { formatTimeRange } from '../../../utils/date-formatter'
import { useTransaction } from '../../../providers/TransactionContext'
import YouTubeThumbnail from './components/youtube'

export interface TransactionMethodPageProps {
  setCurrentRoute: Dispatch<SetStateAction<CashierRoutes>>
  user: any
  isLoading: boolean
  // paymentMethods: IPaymentMethods[]
  feeFreeTimes?: FeeFreeTime[]
  transaction_approve_purchase: IApprovePurchaseDto[] | null
  is_approve_purchase_success: boolean
  depositMethods?: IPaymentMethods[]
  withdrawalMethods?: IPaymentMethods[]
}

export const handlerRedirect = () => {
  // FIX
  // window.parent.postMessage('open_identity_dialog', '*')
}

export const GoToProfile = () => {
  // FIX
  // window.parent.postMessage('go_to_profile', '*')
}

export const TransactionMethodPage = (props: TransactionMethodPageProps) => {
  const {
    setCurrentRoute,
    isLoading,
    feeFreeTimes,
    // getPaymentMethodsAction,
    // GetPendingListTransactionApprovePurchaseAction,
    // paymentMethods,
    user,
    transaction_approve_purchase,
    is_approve_purchase_success,
    depositMethods,
    withdrawalMethods,
  } = props
  const { transactionType, setTransactionMethod } = useTransaction()

  const cdnUrl = 'https://cdn-sandbox.pdub.io/global/src/' // FIX

  const [open, setOpen] = useState<boolean>(false)
  // const [hasAnAlertToRead, setHasAnAlertToRead] = useState<boolean>(false);
  // const [hasThePlayerReadTheAlert, setHasThePlayerReadTheAlert] = useState<boolean>(false);

  const [showWelcomeBonusInfo, setShowWelcomeBonusInfo] = useState<boolean>(false)

  const welcomeBonusInfo = [
    {
      title: '$50 ONE TIME ONLY - NO DEPOSIT REQUIRED',
      description: `$50 VIPCLUB Bonus (1x Rollover):
      With a minimal 1x rollover requirement, you can swiftly indulge in the VIP treatment and enjoy the rewards that await you.`,
    },
  ]
  // {
  //   title: '$100 ONE TIME ONLY - NO DEPOSIT REQUIRED',
  //   description: `$100 VIPCLUB Bonus (5x Rollover)
  //   With a 5x rollover requirement, you have ample opportunities to transform this bonus into substantial winnings.`,
  // },
  // {
  //   title: '$200 ONE TIME ONLY - NO DEPOSIT REQUIRED',
  //   description: `$200 VIPCLUB Bonus (8x Rollover)
  //   With an 8x rollover requirement, each wager brings you closer to reaping extraordinary Financial rewards, compliments of VIPCLUB!`,
  // },

  /*   useEffect(() => {
    if (type == null || type == undefined) {
      cashierDialog.isShow
        ? setCashierDialogAction({ isShow: true, type: 'cashier', transactionType: null })
        : getHistory().push('/')
    } else {
      getPaymentMethodsAction({ playerNumber: user.username, type: type })

      if (type == TransactionTypeEnum.WITHDRAWAL) {
        GetPendingListTransactionApprovePurchaseAction()
      }
    }

    setCDnUrl(getAppSettings().imagesCdn ?? '')
  }, []) */

  // useEffect(() => {
  //   if (type == TransactionTypeEnum.WITHDRAWAL) {
  //     if (paymentMethods.find((x: IPaymentMethods) => x.id == PaymentMethodEnum.INSTANT_PAY && (x.playerHasActive ?? false) == true)) {
  //       setHasAnAlertToRead(true);
  //       setHasThePlayerReadTheAlert(false);
  //     }
  //   }
  // }, [paymentMethods]);

  return (
    <>
      {/* Loading FIX */}

      {/* Approve Purchase Step */}
      {transactionType == TransactionTypeEnum.WITHDRAWAL && !is_approve_purchase_success ? (
        <ApprovePurchaseStep
          setCurrentRoute={setCurrentRoute}
          transaction_approve_purchase={transaction_approve_purchase}
        />
      ) : (
        <div className='container mx-auto px-4 py-8'>
          <div className='text-center mb-8 border-b border-primary text-primary pb-4'>
            <h2 className='text-xl'>
              Select a {transactionType === TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'} Method
            </h2>
          </div>

          {!isLoading &&
            depositMethods &&
            depositMethods?.length < 2 &&
            transactionType === TransactionTypeEnum.DEPOSIT && (
              <div
                className='bg-blue-100 border border-blue-500 text-blue-900 px-4 py-3 rounded relative mb-6'
                role='alert'
              >
                <strong className='font-bold'>Want More Deposit Methods?</strong>
                <span className='block sm:inline'>
                  Verify your identity via Live Chat today to receive access to additional deposit methods like Debit
                  Card, Zelle, Paypal, and much more!
                </span>
              </div>
            )}

          <div className='mb-4'>
            <button
              className='bg-transparent border-2 border-primary hover:opacity-50 text-white py-2 px-4 rounded'
              onClick={() => setCurrentRoute(CashierRoutes.CASHIER)}
            >
              Back
            </button>
          </div>

          {/* Payment Methods Grid */}
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            {withdrawalMethods
              ?.filter((x) => transactionType === TransactionTypeEnum.WITHDRAWAL && x.id !== PaymentMethodEnum.BITCOIN)
              .map((method) => (
                <div key={method.id} className='mb-4'>
                  <div
                    className={`group p-4 border-2 border-primary text-primary bg-transparent rounded-lg shadow-glow-primary cursor-pointer transition-all transform hover:scale-105 hover:bg-primary hover:text-white ${
                      method.playerHasActive === false ? 'opacity-50 cursor-not-allowed' : ''
                    } ${/* Aplica altura solo en resoluciones medianas hacia arriba */ 'md:h-40'}`}
                    onClick={() => {
                      if (method.playerHasActive === false) {
                        setOpen(true)
                        return
                      }
                      setTransactionMethod(method)
                      setCurrentRoute(CashierRoutes.BONUS)
                    }}
                  >
                    <div className='flex flex-col justify-between md:h-full'>
                      <div className='flex items-center'>
                        <img
                          src={cdnUrl + method.image}
                          alt={method.name}
                          className='w-10 h-10 rounded-full mr-4 border-2 border-primary group-hover:border-white transition-colors duration-300'
                        />
                        <div>
                          <h3 className='font-bold text-lg group-hover:text-white transition-colors duration-300'>
                            {method.name}
                          </h3>
                        </div>
                      </div>
                      {/* Información con saltos de línea en desktop y barras en mobile */}
                      <div className='mt-4 text-sm text-gray-300 group-hover:text-gray-300 transition-colors duration-300'>
                        {/* Mostrado en pantallas grandes */}
                        <div className='hidden md:block'>
                          Min: ${method.deposit_min || 0} <br />
                          Max: ${method.deposit_max || 0} <br />
                          Fee: {method.fee || 0}%
                        </div>
                        {/* Mostrado en pantallas pequeñas */}
                        <div className='block md:hidden'>
                          Min: ${method.deposit_min || 0} | Max: ${method.deposit_max || 0} | Fee: {method.fee || 0}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {depositMethods
              ?.filter(
                (x) =>
                  transactionType === TransactionTypeEnum.DEPOSIT &&
                  x.id !== PaymentMethodEnum.BONUS &&
                  x.id !== PaymentMethodEnum.CASH_APP,
              )
              .map((method) => (
                <div key={method.id} className='mb-4'>
                  <div
                    className={`group p-4 border-2 border-primary text-primary bg-transparent rounded-lg shadow-glow-primary cursor-pointer transition-all transform hover:scale-105 hover:bg-primary hover:text-white ${
                      method.playerHasActive === false ? 'opacity-50 cursor-not-allowed' : ''
                    } ${/* Aplica altura solo en resoluciones medianas hacia arriba */ 'md:h-40'}`}
                    onClick={() => {
                      if (method.playerHasActive === false) {
                        setOpen(true)
                        return
                      }
                      setTransactionMethod(method)
                      setCurrentRoute(CashierRoutes.BONUS)
                    }}
                  >
                    <div className='flex flex-col justify-between md:h-full'>
                      <div className='flex items-center'>
                        <img
                          src={cdnUrl + method.image}
                          alt={method.name}
                          className='w-10 h-10 rounded-full mr-4 border-2 border-primary group-hover:border-white transition-colors duration-300'
                        />
                        <div>
                          <h3 className='font-bold text-lg group-hover:text-white transition-colors duration-300'>
                            {method.name}
                          </h3>
                        </div>
                      </div>
                      {/* Información con saltos de línea en desktop y barras en mobile */}
                      <div className='mt-4 text-sm text-gray-300 group-hover:text-gray-300 transition-colors duration-300'>
                        {/* Mostrado en pantallas grandes */}
                        <div className='hidden md:block'>
                          Min: ${method.deposit_min || 0} <br />
                          Max: ${method.deposit_max || 0} <br />
                          Fee: {method.fee || 0}%
                        </div>
                        {/* Mostrado en pantallas pequeñas */}
                        <div className='block md:hidden'>
                          Min: ${method.deposit_min || 0} | Max: ${method.deposit_max || 0} | Fee: {method.fee || 0}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* Welcome Bonus Info Section */}
          {transactionType == TransactionTypeEnum.DEPOSIT &&
            user.site == SiteIDEnum.VIPCLUB &&
            user.player_profile_settings_payment_method_bonus_enabled == true &&
            !user.player_profile_settings_payment_method_bonus_claimed &&
            !user.player_profile_settings_payment_method_bonus_id &&
            depositMethods?.find((x) => x.id == PaymentMethodEnum.BONUS) && (
              <>
                {depositMethods
                  .filter((x) => x.id == PaymentMethodEnum.BONUS)
                  .map((method) => (
                    <div key={`pm_${method.id}`}>
                      <div className='flex items-center justify-between p-4 rounded shadow-lg bg-yellow-200 mb-4'>
                        <div className='flex items-center'>
                          <img src={cdnUrl + method.image} alt={method.name} className='w-10 h-10 rounded-full mr-4' />
                          <div>
                            <h3 className='font-bold text-lg'>{method.name}</h3>
                            <p className='text-gray-600'>Available for deposit bonuses.</p>
                          </div>
                        </div>
                        <button
                          onClick={() => setShowWelcomeBonusInfo(!showWelcomeBonusInfo)}
                          className='bg-blue-500 text-white px-3 py-1 rounded'
                        >
                          {showWelcomeBonusInfo ? 'Hide Info' : 'Show Info'}
                        </button>
                      </div>

                      {/* Welcome Bonus Info Display */}
                      {showWelcomeBonusInfo &&
                        welcomeBonusInfo.map((message, index) => (
                          <div
                            key={`wb_info_${index}`}
                            className='p-4 mb-4 bg-yellow-100 border border-yellow-500 rounded text-yellow-900'
                          >
                            <h4 className='font-bold mb-2'>{message.title}</h4>
                            <p>{message.description}</p>
                          </div>
                        ))}
                    </div>
                  ))}
              </>
            )}

          {/* Free Withdrawal Period Info FIX */}
          {transactionType === TransactionTypeEnum.WITHDRAWAL &&
            feeFreeTimes?.map((fft) => (
              <div
                key={fft.id}
                className='bg-green-100 border border-green-500 text-green-900 px-4 py-3 rounded relative mb-6'
              >
                <strong className='font-bold'>Free {fft.name} Withdrawal Period</strong>
                <div>
                  {fft.days.map((day) => (
                    <p key={day.week_day_id} className='font-semibold'>
                      {day.week_day.name} - {formatTimeRange(day.start_time, day.end_time)} PST
                    </p>
                  ))}
                </div>
              </div>
            ))}

          {/* Withdrawal Method Alert */}
          {transactionType === TransactionTypeEnum.WITHDRAWAL && user.site === SiteIDEnum.VIPCLUB && (
            <div className='bg-gray-800 border border-yellow-500 text-yellow-500 px-4 py-3 rounded relative mb-6'>
              <strong className='font-bold'>(Instant) Pay Withdrawals Only available to US-based clients</strong>
              <span className='block sm:inline'>(Instant) Pay withdrawals are available for verified users!</span>
              <div className='mt-4'>
                <YouTubeThumbnail />
              </div>
            </div>
          )}

          {/* Dialog for Payment Method Not Available FIX */}
          {open && (
            <div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'>
              <div className='bg-white rounded-lg shadow-lg max-w-sm w-full'>
                <div className='p-4 border-b border-gray-200'>
                  <h2 className='text-xl font-bold'>Payment method not available</h2>
                </div>
                <div className='p-4'>
                  {user.player_verification_block_list === false ? (
                    <p>
                      To gain access to this payment method, please verify your identity. Please select continue to
                      submit a verification request.
                    </p>
                  ) : (
                    <p>Please contact support for more information.</p>
                  )}
                </div>
                <div className='p-4 flex justify-end border-t border-gray-200'>
                  {user.player_verification_block_list === false ? (
                    <button
                      onClick={handlerRedirect}
                      className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    >
                      Continue
                    </button>
                  ) : (
                    <button
                      onClick={() => setOpen(false)}
                      className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
