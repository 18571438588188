import React, { Dispatch, SetStateAction } from 'react'

export interface BoostItem {
  name: string
  status: 'Claim' | 'Claimed' | 'Not Eligible' | 'Expired' | 'Available'
  id: number
  amount: number | null
}

export interface BoostCardProps {
  title: string
  iconUrl: string
  nextBoost: string
  category: string
  items: BoostItem[]
  historyId: number
  setHistoryId: Dispatch<SetStateAction<number>>
  loadingHistory: boolean
  isMobile: boolean
  showHistory: boolean
  onClaimBonus: (item: BoostItem) => void
}

export const BoostCard: React.FC<BoostCardProps> = ({
  title,
  iconUrl,
  category,
  nextBoost,
  items,
  historyId,
  setHistoryId,
  loadingHistory,
  isMobile,
  showHistory,
  onClaimBonus,
}) => (
  <div
    /* 550 Later */
    className={`
      bg-background rounded-lg p-4 w-64 flex flex-col relative
      ${isMobile ? (showHistory ? 'h-[200px]' : 'h-[500px]') : 'h-[500px]'}
    `}
  >
    <div className='absolute top-0 left-0 right-0 bg-primary h-[50px] text-white py-2 text-center text-lg rounded-b-lg'>
      {title}
    </div>
    <div className='flex justify-center mb-4 mt-8'>
      <div
        style={{
          backgroundImage: `url(${iconUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '80px',
          height: '96px',
        }}
      />
    </div>
    <p className='text-center text-primary'>{category}</p>
    <p className='text-gray-400 text-sm text-center mb-8'>{nextBoost}</p>
    {(!isMobile || !showHistory) && (
      <div className='space-y-2 flex-grow overflow-y-auto'>
        {items.map((item) => (
          <div key={item.id} className='flex justify-between items-center'>
            <span className='text-white'>{item.name}</span>
            {item.status === 'Available' ? (
              <button
                onClick={() => onClaimBonus(item)}
                className='px-3 py-1 rounded bg-primary text-white hover:bg-primary-dark'
              >
                Claim
              </button>
            ) : (
              <span
                className={`px-3 py-1 rounded ${
                  item.status === 'Claimed' ? 'bg-secondary text-white' : 'bg-gray-700 text-gray-400'
                }`}
              >
                {item.status}
              </span>
            )}
          </div>
        ))}
      </div>
    )}
    {/* Remove false later */}
    {!isMobile && false && (
      <button
        onClick={() => !loadingHistory && setHistoryId(historyId)}
        className={`rounded py-2 mt-6 absolute bottom-4 left-0 right-0 mx-8
          ${
            loadingHistory
              ? 'bg-gray-600 text-gray-400 cursor-not-allowed'
              : 'bg-primary text-white hover:bg-primary-dark'
          }`}
      >
        History
      </button>
    )}
  </div>
)
