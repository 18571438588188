export interface IApprovePurchaseDto {
  id: number
  site: number
  type: number
  method: number
  approved_purchase_id: any
  player_number: string
  bank: number
  amount: number
  check_amount: number
  amount_received: number
  fee: number
  house_fee: any
  tp_operation_fee: any
  amount_loaded: number
  exchange_rate: number
  trade_rate: string
  btc_amount_received: number
  ca_fee: any
  amount_after_ca_fee: any
  ca_identifier: any
  pp_order_id: any
  stripe_id: string
  stripe_fee: number
  cbio_fee: any
  check_id: any
  paxful_trade_id: any
  paxful_fee: any
  fee_revenue: number
  account: string | null
  bonus: number
  notes: any
  status: number
  admin_verification: number
  queue_status_id: number
  dgs_transaction_id: string
  ip: string
  fingerprint: string
  tpi_was_notified: any
  tpi_was_paid: any
  tpi_code: any
  tpi_code_expired_at: any
  created_by: string
  updated_by: string
  created_at: string
  updated_at: string
  parent_id: number | null
  payment_method: PaymentMethod
  stripe_checkout_session: StripeCheckoutSession | null
}

export interface PaymentMethod {
  id: number
  name: string
}

export interface StripeCheckoutSession {
  id: number
  stripe_account_id: number
  transaction_id: number
  stripe_id: string
  invoice_url: string | null
}

export interface IApprovePurchaseRequestDto {
  signature: string
  transactions: IApprovePurchaseIdDto[]
}

export interface IApprovePurchaseIdDto {
  id: number
}

export const EmptyIApprovePurchaseRequestDto: IApprovePurchaseRequestDto = {
  signature: '',
  transactions: [],
}
