import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ArrowDownCircle, ArrowUpCircle } from 'lucide-react'

import { IPlayerTransactionLimitDetails } from '../../../../models/player-transaction-limit-details'
import { LimitTypeEnum } from '../../../../models/player-transaction-limit'

import { PlayerStatusEnum } from '../../../../enum/player-v2/PlayerStatusEnum'
import { PlayerTypeV2Enum } from '../../../../enum/player-v2/PlayerTypeV2Enum'

import { SiteIDEnum } from '../../../../enum/site/site-id-enum'
import { TransactionTypeEnum } from '../../../../enum/transactions/TransactionTypeEnum'
import { useToast } from '../../../../providers/ToastContext'
import { useTransaction } from '../../../../providers/TransactionContext'
import { CashierRoutes } from '../../../../enum/routes/routes-site-enum'

export interface FormTransactionTypeProps {
  rollover: any
  isLoading: boolean
  userBalances: any
  user: any
  setCurrentRoute: Dispatch<SetStateAction<CashierRoutes>>
  transactionLimitDetails?: IPlayerTransactionLimitDetails
  handleOpen: (data: any) => void
  handleContOpen: (data: any) => void
}

export const FormTransactionType = (props: FormTransactionTypeProps) => {
  const {
    user,
    rollover,
    isLoading,
    userBalances,
    transactionLimitDetails,
    handleOpen,
    handleContOpen,
    setCurrentRoute,
  } = props

  const { addToast } = useToast()

  const [showConfirmEmailDialog, setShowConfirmEmailDialog] = useState<boolean>(false)
  const [showDialogSetPassword, setShowDialogSetPassword] = useState<boolean>(false)

  const { setTransactionBonus, setTransactionType, resetTransaction } = useTransaction()

  let isOnBonus = rollover != null
  const canDeposit = transactionLimitDetails?.canDeposit || false

  useEffect(() => {
    if (
      user &&
      user?.can_use_cashier &&
      user?.can_use_cashier.can_use_cashier == false &&
      user?.can_use_cashier.step == 2
    ) {
      setShowConfirmEmailDialog(true)
    }

    if (user && user?.can_reset_password != null && user?.can_reset_password == false && showDialogSetPassword) {
      setShowDialogSetPassword(false)
    }
  }, [user])

  if (Array.isArray(rollover)) {
    isOnBonus = rollover.length !== 0
  }

  const showTransactionLimitMessage = () => {
    if (transactionLimitDetails) {
      if (
        transactionLimitDetails.remaining[LimitTypeEnum.Hourly].amount <= 0 ||
        transactionLimitDetails.remaining[LimitTypeEnum.Hourly].quantity <= 0
      ) {
        addToast("Hey, It's time for a break.. Please try depositing again later.", 'error')
      } else if (
        transactionLimitDetails.remaining[LimitTypeEnum.Daily].amount <= 0 ||
        transactionLimitDetails.remaining[LimitTypeEnum.Daily].quantity <= 0
      ) {
        addToast(
          'It seems you have reached our max daily deposit limit. Please note these limits are set for your own protection. Feel free to reach out to our customer support team for more details.',
          'error',
        )
      } else {
        addToast(
          'It seems you have reached our max weekly deposit limit. Please note these limits are set for your own protection. Feel free to reach out to our customer support team for more details.',
          'error',
        )
      }
    }
  }

  const ShowReadOnlyMessage = () => {
    addToast('Action not allowed, please contact customer support.', 'warning')
  }

  // const url = `${getAppSettings().baseUrl}resources/instantpayvid.mp4`;

  const selectTransactionType = (transactionType: number) => {
    setTransactionBonus(null)
    setTransactionType(transactionType)
    setCurrentRoute(CashierRoutes.METHOD)
  }

  const GoToProfile = () => {
    // FIX
    window.parent.postMessage('go_to_profile', '*')
  }

  useEffect(() => {
    resetTransaction()
  }, [])

  return (
    <>
      <div className='flex items-center justify-center py-0 mb-4'>
        <div className='container mx-auto'>
          {/* Low Balance Alert */}
          {!isLoading && userBalances?.RealAvailBalance < 10 && (
            <div className='bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 my-4' role='alert'>
              <div className='font-bold'>Low Balance!</div>
              <p>
                Looks like you have a low balance! You must deposit additional funds before you are able to place
                wagers.
              </p>
            </div>
          )}

          {/* No Access Message */}
          {!user?.can_withdraw && !user?.can_deposit && (
            <div className='p-6 rounded-lg shadow-lg bg-cover bg-center text-center'>
              <h3 className='text-lg font-bold mb-4'>No Access to These Options</h3>
              <p>
                We&lsquo;re sorry, but you don&lsquo;t have permission to access these options. If you believe you
                should have access, please contact the support team for assistance.
              </p>
            </div>
          )}

          {/* Deposit and Withdrawal Cards */}
          {!isLoading && (
            <div className='p-2'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                {user?.can_deposit && (
                  <div
                    className={`group p-6 border-2 border-primary text-primary bg-transparent rounded-lg shadow-glow-primary hover:bg-primary hover:text-white transition duration-300 ease-in-out cursor-pointer ${
                      user?.status === PlayerStatusEnum.READ_ONLY ? 'blur-sm' : ''
                    }`}
                    onClick={() => {
                      if (
                        user?.player_type_id !== PlayerTypeV2Enum.CREDIT &&
                        user?.status !== PlayerStatusEnum.READ_ONLY
                      ) {
                        canDeposit ? selectTransactionType(TransactionTypeEnum.DEPOSIT) : showTransactionLimitMessage()
                      } else {
                        ShowReadOnlyMessage()
                      }
                    }}
                  >
                    <ArrowUpCircle className='text-primary group-hover:text-white text-4xl mx-auto mb-4 transition duration-300 ease-in-out' />
                    <h3 className='text-lg font-bold group-hover:text-white'>Deposit</h3>
                  </div>
                )}
                {user?.can_withdraw && (
                  <div
                    className={`group p-6 border-2 border-secondary text-secondary bg-transparent rounded-lg shadow-glow-secondary hover:bg-secondary hover:text-white transition duration-300 ease-in-out cursor-pointer ${
                      isOnBonus ? 'blur-sm' : ''
                    }`}
                    onClick={() => {
                      if (
                        user?.player_type_id !== PlayerTypeV2Enum.CREDIT &&
                        user?.status !== PlayerStatusEnum.READ_ONLY
                      ) {
                        selectTransactionType(TransactionTypeEnum.WITHDRAWAL)
                      } else {
                        ShowReadOnlyMessage()
                      }
                    }}
                  >
                    <ArrowDownCircle className='text-secondary group-hover:text-white text-4xl mx-auto mb-4 transition duration-300 ease-in-out' />
                    <h3 className='text-lg font-bold group-hover:text-white'>Withdrawal</h3>
                  </div>
                )}

                {/* Set Password Button */}
                {user?.site !== SiteIDEnum.VIPCLUB && user?.can_reset_password && (
                  <div className='col-span-1 text-center'>
                    <button
                      className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
                      onClick={() => setShowDialogSetPassword(true)}
                    >
                      Set Cashier Password
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Bonus Section */}
          {rollover && (
            <div className='p-4 bg-cover border-2 border-primary bg-center rounded-lg shadow-lg mt-4'>
              <div className='text-left text-white'>
                <h4 className='font-bold'>{user?.username ?? '-'}</h4>
                <p className='text-sm'>Bonus Status</p>
              </div>
              {/* Rollover Details */}
              {rollover?.bonus?.category === 'deposit' && (
                <div className='mt-4 text-justify'>
                  <h4 className='text-white-500 text-3xl'>${rollover.remaining_rollover}</h4>
                  <p className='text-gray-400'>Remaining Rollover</p>
                </div>
              )}

              {/* Buttons for Bonus Actions */}
              <div className='flex justify-between mt-4 space-x-4'>
                <button
                  className='w-1/2 px-4 py-2 border-2 border-primary text-primary bg-transparent rounded-lg shadow-glow-primary hover:bg-primary hover:text-white transition duration-300 ease-in-out'
                  onClick={handleOpen}
                >
                  View Wagers
                </button>
                <button
                  className='w-1/2 px-4 py-2 border-2 border-primary text-primary bg-transparent rounded-lg shadow-glow-secondary hover:bg-primary hover:text-white transition duration-300 ease-in-out'
                  onClick={handleContOpen}
                >
                  Contribution Table
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Confirm Email Dialog */}
      {showConfirmEmailDialog && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div className='bg-white rounded-lg shadow-xl p-6 w-96'>
            <h3 className='text-lg font-bold mb-4'>Email Verification</h3>
            <p className='mb-6'>
              Hi {user.external_username ?? user.username}, in order to use the cashier, please check your email and
              verify your account.
            </p>
            <button
              onClick={() => GoToProfile()}
              className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mx-auto block'
            >
              Update Profile
            </button>
          </div>
        </div>
      )}
    </>
  )
}
