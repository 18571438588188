import React, { useState } from 'react'

export interface Column {
  title: string
  data: string
  format?: 'date' | 'amount' | 'string' | ((value: any) => string)
}

interface DataTableProps {
  data: Array<any>
  columns: Array<Column>
}

const DataTableComponent: React.FC<DataTableProps> = ({ data, columns }) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const formatDate = (dateString: string) => {
    if (!dateString) return '-'
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  const formatAmount = (amount: number) => {
    if (amount == null) return '-'
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount)
  }

  const formatValue = (value: any, format?: Column['format']) => {
    if (value == null) return '-'
    if (typeof format === 'function') {
      return format(value)
    }
    switch (format) {
      case 'date':
        return formatDate(value)
      case 'amount':
        return formatAmount(value)
      case 'string':
      default:
        return String(value)
    }
  }

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj)
  }

  const toggleRowExpand = (rowIndex: number) => {
    setExpandedRows((prev) => (prev.includes(rowIndex) ? prev.filter((i) => i !== rowIndex) : [...prev, rowIndex]))
  }

  const PlusIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='#c8a964' width='24' height='24' viewBox='0 0 300.003 300.003'>
      <path d='M150,0C67.159,0,0.001,67.159,0.001,150c0,82.838,67.157,150.003,149.997,150.003S300.002,232.838,300.002,150    C300.002,67.159,232.839,0,150,0z M213.281,166.501h-48.27v50.469c-0.003,8.463-6.863,15.323-15.328,15.323    c-8.468,0-15.328-6.86-15.328-15.328v-50.464H87.37c-8.466-0.003-15.323-6.863-15.328-15.328c0-8.463,6.863-15.326,15.328-15.328    l46.984,0.003V91.057c0-8.466,6.863-15.328,15.326-15.328c8.468,0,15.331,6.863,15.328,15.328l0.003,44.787l48.265,0.005    c8.466-0.005,15.331,6.86,15.328,15.328C228.607,159.643,221.742,166.501,213.281,166.501z' />
    </svg>
  )

  const MinusIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='#c8a964' width='24' height='24' viewBox='0 0 330 330'>
      <path d='M281.633,48.328C250.469,17.163,209.034,0,164.961,0C120.888,0,79.453,17.163,48.289,48.328   c-64.333,64.334-64.333,169.011,0,233.345C79.453,312.837,120.888,330,164.962,330c44.073,0,85.507-17.163,116.671-48.328   c31.165-31.164,48.328-72.599,48.328-116.672S312.798,79.492,281.633,48.328z M260.42,260.46   C234.922,285.957,201.021,300,164.962,300c-36.06,0-69.961-14.043-95.46-39.54c-52.636-52.637-52.636-138.282,0-190.919   C95,44.042,128.901,30,164.961,30s69.961,14.042,95.459,39.54c25.498,25.499,39.541,59.4,39.541,95.46   S285.918,234.961,260.42,260.46z' />
      <path d='M254.961,150H74.962c-8.284,0-15,6.716-15,15s6.716,15,15,15h179.999c8.284,0,15-6.716,15-15S263.245,150,254.961,150z' />
    </svg>
  )

  const renderMobileRow = (row: any, rowIndex: number) => {
    const isExpanded = expandedRows.includes(rowIndex)

    return (
      <React.Fragment key={rowIndex}>
        <tr className='border-b hover:bg-gray-700'>
          <td className='w-10 py-3'>
            <button onClick={() => toggleRowExpand(rowIndex)} className='focus:outline-none'>
              {isExpanded ? <MinusIcon /> : <PlusIcon />}
            </button>
          </td>
          <td className='w-1/3 px-2 py-3'>{formatValue(getNestedValue(row, columns[1].data), columns[1].format)}</td>
          <td className='w-1/3 px-2 py-3'>{formatValue(getNestedValue(row, columns[2].data), columns[2].format)}</td>
          <td className='w-1/3 px-2 py-3'>{formatValue(getNestedValue(row, columns[3].data), columns[3].format)}</td>
        </tr>
        {isExpanded && (
          <tr>
            <td colSpan={4} className='px-4 py-3 bg-gray-700'>
              <div className='mb-2'>
                <strong>{columns[0].title}:</strong>{' '}
                {formatValue(getNestedValue(row, columns[0].data), columns[0].format)}
              </div>
              <div>
                <strong>{columns[4].title}:</strong>{' '}
                {formatValue(getNestedValue(row, columns[4].data), columns[4].format)}
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    )
  }

  const renderDesktopRow = (row: any, rowIndex: number) => (
    <tr key={rowIndex} className='border-b hover:bg-gray-700'>
      {columns.map((column, colIndex) => (
        <td key={colIndex} className='px-4 py-3'>
          {formatValue(getNestedValue(row, column.data), column.format)}
        </td>
      ))}
    </tr>
  )

  const renderMobileHeader = () => (
    <tr className='text-left'>
      <th className='w-10'></th>
      <th className='w-1/3 px-2 py-3'>{columns[1].title}</th>
      <th className='w-1/3 px-2 py-3'>{columns[2].title}</th>
      <th className='w-1/3 px-2 py-3'>{columns[3].title}</th>
    </tr>
  )

  const renderDesktopHeader = () => (
    <tr>
      {columns.map((column, index) => (
        <th key={index} className='px-4 py-3 text-left'>
          {column.title}
        </th>
      ))}
    </tr>
  )

  if (data.length === 0) {
    return (
      <div className='w-full text-center py-8'>
        <p className='text-white text-lg'>No data available</p>
      </div>
    )
  }

  return (
    <div className='overflow-x-auto w-full'>
      <table className='w-full border-collapse bg-background shadow-sm'>
        <thead className='bg-primary text-white'>
          {columns.length >= 5 ? (
            <>
              <tr className='sm:hidden'>{renderMobileHeader()}</tr>
              <tr className='hidden sm:table-row md:contents'>{renderDesktopHeader()}</tr>
            </>
          ) : (
            renderDesktopHeader()
          )}
        </thead>
        <tbody>
          {columns.length >= 5
            ? data.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <tr className='sm:hidden'>{renderMobileRow(row, rowIndex)}</tr>
                  <tr className='hidden sm:table-row md:contents'>{renderDesktopRow(row, rowIndex)}</tr>
                </React.Fragment>
              ))
            : data.map((row, rowIndex) => renderDesktopRow(row, rowIndex))}
        </tbody>
      </table>
    </div>
  )
}
export default DataTableComponent
