import React, { createContext, useContext, useState, ReactNode } from 'react'
import { IPaymentMethods } from '../models/paymentMethods'
import { IInsertTransactionResponse } from '../models/Transactions/transaction'

type TransactionContextType = {
  transactionMethod: IPaymentMethods | null
  setTransactionMethod: (method: IPaymentMethods) => void
  transactionType: number
  setTransactionType: (method: number) => void
  transactionBonus: any
  setTransactionBonus: (data: any) => void
  isWelcomeBonus: boolean
  setIsWelcomeBonus: (isBonus: boolean) => void
  transactionAccount: string
  setTransactionAccount: (account: string) => void
  transactionAmount: number
  setTransactionAmount: (amount: number) => void
  transactionName: string
  setTransactionName: (name: string) => void
  transaction: null | IInsertTransactionResponse
  setTransaction: (transaction: null | IInsertTransactionResponse) => void
  resetTransaction: () => void
}

const TransactionContext = createContext<TransactionContextType | undefined>(undefined)

export const TransactionProvider = ({ children }: { children: ReactNode }) => {
  const [transactionType, setTransactionType] = useState<number>(-1)
  const [transactionBonus, setTransactionBonus] = useState<any>(null)
  const [transactionMethod, setTransactionMethod] = useState<IPaymentMethods | null>(null)
  const [isWelcomeBonus, setIsWelcomeBonus] = useState<boolean>(false)
  const [transactionAccount, setTransactionAccount] = useState('')
  const [transactionAmount, setTransactionAmount] = useState(0)
  const [transactionName, setTransactionName] = useState('')
  const [transaction, setTransaction] = useState<null | IInsertTransactionResponse>(null)

  const resetTransaction = () => {
    setTransactionType(-1)
    setTransactionBonus(null)
    setTransactionMethod(null)
    setIsWelcomeBonus(false)
    setTransactionAccount('')
    setTransactionAmount(0)
    setTransactionName('')
    setTransaction(null)
  }

  return (
    <TransactionContext.Provider
      value={{
        transactionMethod,
        setTransactionMethod,
        transactionType,
        setTransactionType,
        transactionBonus,
        setTransactionBonus,
        isWelcomeBonus,
        setIsWelcomeBonus,
        transactionAccount,
        setTransactionAccount,
        transactionAmount,
        setTransactionAmount,
        transactionName,
        setTransactionName,
        transaction,
        setTransaction,
        resetTransaction,
      }}
    >
      {children}
    </TransactionContext.Provider>
  )
}

export const useTransaction = (): TransactionContextType => {
  const context = useContext(TransactionContext)
  if (context === undefined) {
    throw new Error('useTransaction should be used inside TransactionProvider')
  }
  return context
}
