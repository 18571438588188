import React, { Dispatch, SetStateAction } from 'react'
import { SWRProvider } from '../..'
import { ToastProvider } from './providers/ToastContext'
import CashierContainer from './app'
import { TransactionProvider } from './providers/TransactionContext'
import { UserMetadataProvider } from './providers/UserMetadataContext'

type CashierProps = {
  isCashierVisible: boolean
  setIsCashierOpen: Dispatch<SetStateAction<boolean>>
}

const Cashier: React.FC<CashierProps> = ({ isCashierVisible, setIsCashierOpen }) => {
  return (
    <SWRProvider>
      <TransactionProvider>
        <UserMetadataProvider>
          <ToastProvider>
            <CashierContainer isCashierVisible={isCashierVisible} setIsCashierOpen={setIsCashierOpen} />
          </ToastProvider>
        </UserMetadataProvider>
      </TransactionProvider>
    </SWRProvider>
  )
}

export default Cashier
