export const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const isPasswordValid = (password: string) => {
  /* eslint-disable-next-line */
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&_\-])[A-Za-z\d.@$!%*#?&_\-]{8,}$/
  return passwordRegex.test(password)
}

export function isArray(value: any) {
  return Array.isArray(value)
}
