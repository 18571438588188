import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { FormTransactionType } from './components/FormTransactionType'
import { ContDialog } from './components/ContDiaglog'
import { ContTable } from './components/ContTable'
import { WageringDialoge } from './components/WageringDialoge'
import { WageringHistoryTable } from './components/WageringHistoryTable'
/* import FingerprintJS from '@fingerprintjs/fingerprintjs-pro' */
/* import { Loading } from '../../../shared/loading/loading.component' */
import { IPlayerTransactionLimitDetails } from '../../../models/player-transaction-limit-details'
/* import { ICashierDialog } from '../../../models/cashier-transaction' */
/* import { getHistory } from '../../../utils/history.util' */
import { SiteIDEnum } from '../../../enum/site/site-id-enum'
import { CashierRoutes } from '../../../enum/routes/routes-site-enum'
/* import { IAppSettingsDto } from '../../../models/common'
import { FingerprintEnum } from '../../../enum/fingerprint/fingerprint' */
// import { GetFingerPrint } from '../../../shared/functions/common'

export interface CashierPageProps {
  setCurrentRoute: Dispatch<SetStateAction<CashierRoutes>>
  isLoading: boolean
  user: any
  rollover: any
  userBalances: any
  transactionLimitDetails?: IPlayerTransactionLimitDetails
  // app_settings: IAppSettingsDto
}
export const CashierPage = (props: CashierPageProps) => {
  const {
    setCurrentRoute,
    user,
    isLoading,
    rollover,
    // ip,
    userBalances,
    transactionLimitDetails,
    // getPlayerRolloverAction,
    // setTransactionIpAction,
    // setTransactionTypeAction,
    // setTransactionBonusAction,
    // setTransactionFingerprintAction,
    // setNeedCashierFowardAction,
    // getFeeFreeTimesAction,
    // setTransactionCleanAction,
    // clearPaymentMethodsAction,
    // setCashierDialogAction,
    // getTransactionLimitAction,
    // resetTransactionAction,
    // clearCheckSmsCodeAction,
    // cashierDialog,
    // getUserInfoAction,
    // SetValidateUseWithdrawalVerificationCodeAction,
  } = props

  // const [fingerprint, setFingerprint] = useState<string | null>(null)
  const [open, setOpen] = useState(false)
  const [contOpen, setContOpen] = useState(false)
  const [showConfirmEmailDialog, setShowConfirmEmailDialog] = useState<boolean>(false)

  useEffect(() => {
    try {
      if (user && user.site && user.site !== SiteIDEnum.VIPCLUB && user.can_use_cashier != null) {
        // FIX
        /*         switch (user.can_use_cashier.step) {
          case 1:
            getHistory().push('/update-user-profile')
            break
          case 2:
            setShowConfirmEmailDialog(true)
            break
          case 3:
            getHistory().push('/cognito-flow')
            break
          default:
            if (!playerNumber) setPlayerNumber(user.username)
            break
        } */
      }
    } catch (error) {
      console.error(error)
    }
  }, [user])

  return (
    <>
      {/*  <Loading loading={isLoading || userDataLoading} url_logo={app_settings.logo.url} /> */}
      <div className='flex flex-col items-center text-center'>
        <FormTransactionType
          setCurrentRoute={setCurrentRoute}
          isLoading={isLoading}
          user={user}
          rollover={rollover}
          userBalances={userBalances}
          handleOpen={() => setOpen(true)}
          handleContOpen={() => setContOpen(true)}
          transactionLimitDetails={transactionLimitDetails}
        />

        {/* Wagering Dialog */}
        <WageringDialoge
          open={open}
          onClose={() => setOpen(false)}
          data={<WageringHistoryTable rows={rollover ? rollover.wager_data : []} />}
        />

        {/* Contribution Dialog */}
        <ContDialog
          open={contOpen}
          onClose={() => setContOpen(false)}
          data={<ContTable rows={rollover ? rollover.contribution_table : []} />}
        />
      </div>

      {/* Confirm Email Dialog */}
      {showConfirmEmailDialog && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
          <div className='bg-white rounded-lg shadow-xl p-6 w-96'>
            <h3 className='text-lg font-bold mb-4'>Email Verification</h3>
            <p className='mb-6'>
              Hi {user.external_username}, to use the cashier, please check your email and verify your account.
            </p>
            <button
              onClick={() => setShowConfirmEmailDialog(false)}
              className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  )
}
