// useAnimationsModal.ts
import { useState, useCallback } from 'react'

type ModalState = 'idle' | 'loading' | 'success' | 'error'

export const useAnimationsModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [message, setMessage] = useState('')
  const [state, setState] = useState<ModalState>('idle')

  const showLoading = useCallback((msg = 'Loading...') => {
    setIsVisible(true)
    setMessage(msg)
    setState('loading')
  }, [])

  const showSuccess = useCallback((msg = 'Success!') => {
    setMessage(msg)
    setState('success')
    setTimeout(() => {
      setIsVisible(false)
      setState('idle')
    }, 2000)
  }, [])

  const showError = useCallback((msg = 'An error occurred.') => {
    setMessage(msg)
    setState('error')
    setTimeout(() => {
      setIsVisible(false)
      setState('idle')
    }, 2000)
  }, [])

  const hide = useCallback(() => {
    setIsVisible(false)
    setState('idle')
  }, [])

  return {
    isVisible,
    message,
    state,
    showLoading,
    showSuccess,
    showError,
    hide,
  }
}
