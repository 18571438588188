import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'

import ConfirmTable from './cards/reviewTable'

import { TransactionTypeEnum } from '../../../enum/transactions/TransactionTypeEnum'
import { PaymentMethodEnum } from '../../../enum/transactions/PaymentMethodEnum'
import { BonusAmountTypeEnum } from '../../../enum/bonus/bonus-type-amount-enum'
import { CashierRoutes } from '../../../enum/routes/routes-site-enum'

import { GetTimeZone } from '../../../shared/functions/common'

import { useToast } from '../../../providers/ToastContext'
import { useUserMetadata } from '../../../providers/UserMetadataContext'
import { useTransaction } from '../../../providers/TransactionContext'
import { insertTransaction } from '../../../api/confirm-transaction/insert-transaction'
import { sendSmsCode } from '../../../api/confirm-transaction/send-sms-code'
import { validateSmsCode } from '../../../api/confirm-transaction/validate-sms-code'
import { CircleAlert } from 'lucide-react'

export interface ExternalProps {
  setCurrentRoute: Dispatch<SetStateAction<CashierRoutes>>
  isLoading: boolean
  user: any
  // smsCodeCheck: boolean | null
  // isTransactionSuccess: boolean
}

const isStripeMethod = (method?: number) => {
  return [PaymentMethodEnum.INSTANT_APPLE_PAY, PaymentMethodEnum.DEBIT_CARD, PaymentMethodEnum.CASH_APP_STRIPE].indexOf(
    Number(method),
  ) != -1
    ? true
    : false
}

export const ConfirmTransactionPage = (props: ExternalProps) => {
  const {
    setCurrentRoute,
    isLoading,
    user,
    // smsCodeCheck,
    // isTransactionSuccess,
    // sendSmsCodeAction,
    // checkSmsCodeAction,
    // setCashierDialogAction,
    // insertTransactionResetAction,
    // setSmsCodeNullAction,
  } = props

  const { addToast } = useToast()
  const { ip: ipAddress, fingerprint } = useUserMetadata()
  const {
    transactionType,
    transactionMethod,
    transactionBonus,
    transactionAmount,
    transactionAccount,
    transactionName,
    setTransactionName,
    setTransaction,
  } = useTransaction()

  const [disableSubmit, setDisableSubmit] = useState(true)
  const [agreedStripeReceipt, setAgreedStripeReceipt] = useState(
    isStripeMethod(transactionMethod?.id ?? -1) ? false : true,
  )
  const [agreed, setAgreed] = useState(false)
  const [agreedFee, setAgreedFee] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [smsCodeCheck, setSmsCodeCheck] = useState(false)

  const cdn = 'https://cdn-sandbox.pdub.io/global/src/'

  useEffect(() => {
    if (
      transactionType == TransactionTypeEnum.WITHDRAWAL ||
      [PaymentMethodEnum.BITCOIN, PaymentMethodEnum.INSTANT_BITCOIN, PaymentMethodEnum.BONUS].some(
        (x) => x == transactionMethod?.id,
      )
    ) {
      setDisableSubmit(false)
    }

    if (transactionType == TransactionTypeEnum.WITHDRAWAL && user.withdrawal_requires_verification_code) {
      // todo handle response here FIX
      sendSmsCode(user.username)
    }
  }, [])

  const handleInsertTransaction = async () => {
    setDisableSubmit(!disableSubmit)
    // fix set is transaction success
    const transactionReq = await insertTransaction({
      transaction_type_id: transactionType,
      payment_method_id: Number(transactionMethod?.id),
      amount: transactionAmount,
      ip: String(ipAddress),
      account:
        transactionType === TransactionTypeEnum.WITHDRAWAL && transactionMethod?.id === PaymentMethodEnum.INSTANT_PAY
          ? user.email
          : transactionAccount == null
          ? null
          : transactionAccount,
      bonus: transactionBonus && transactionBonus.id ? transactionBonus.id : null,
      name: transactionName ?? null,
      fingerprint: String(fingerprint),
      site_id: user.site,
      time_zone: GetTimeZone(),
    })

    if (transactionReq?.data) {
      setTransaction(transactionReq?.data)
      setCurrentRoute(CashierRoutes.COMPLETE)
    } else {
      addToast('An error occurred while processing the payment, please contact support', 'error')
    }

    setDisableSubmit(!disableSubmit)
  }

  /*   useEffect(() => {
    if (isTransactionSuccess) {
      cashierDialog.isShow
        ? setCashierDialogAction({ isShow: true, type: '/payment', transactionType: null })
        : getHistory().push('/payment')
    }
  }, [isTransactionSuccess]) */

  const handleAgree = (event: any) => {
    if (transactionName == '' || transactionName == null) {
      addToast('Please enter your full name.', 'error')
    } else {
      setAgreed(event.target.checked)
    }
  }

  const handleAgreeFee = (event: any) => {
    setAgreedFee(event.target.checked)
  }

  function createData(dataType: any, dataAmount: any) {
    return { dataType, dataAmount }
  }

  const getFeeAmount = (amount: any, fee: any) => {
    const feePercent = fee / 100

    amount = parseInt(amount)
    // feePercent = parseInt(feePercent);
    const finalFeeAmount = amount * feePercent
    return finalFeeAmount < 1 && fee != 0 ? 1 : Math.round(finalFeeAmount)
  }

  const getBonusMatch = () => {
    if (transactionBonus != null) {
      if (transactionBonus?.type_amount_id == BonusAmountTypeEnum.FIXED) {
        return `$${transactionBonus.match_multiplier * 100}`
      }
      return `${transactionBonus.match_multiplier * 100}%`
    }
    return ''
  }

  const getBonusMatchAmount = (amount: number, transactionBonus: any) => {
    if (transactionBonus != null && transactionBonus.category == 'deposit') {
      if (transactionBonus.type_amount_id == BonusAmountTypeEnum.FIXED) {
        return transactionBonus.match_multiplier * 100
      }
      return amount * transactionBonus.match_multiplier
    }
    return 0
  }

  const getTotal = (type: any, amount: any, bonusAmount: any) => {
    if (type == TransactionTypeEnum.DEPOSIT) {
      return parseInt(amount) + parseInt(bonusAmount)
    }
    return parseInt(amount)
  }

  const getTotalForTransaction = (type: any, amount: any, fee: any) => {
    if (type == TransactionTypeEnum.DEPOSIT) {
      return parseInt(amount) + parseInt(fee)
    }
    return parseInt(amount) - parseInt(fee)
  }

  const bitcoinRows = [
    createData('Amount', '$' + transactionAmount),
    createData('Fee', '$' + getFeeAmount(transactionAmount, transactionMethod?.fee ?? 0)),
    createData(getBonusMatch() + ' Bonus', `$${getBonusMatchAmount(transactionAmount, transactionBonus)}`),
    createData(
      transactionType == TransactionTypeEnum.DEPOSIT
        ? `${transactionBonus == null ? 'Total Funded to Account' : 'Total funded (cash + bonus)'}`
        : 'Total Withdrawn from Account',
      '$' + getTotal(transactionType, transactionAmount, getBonusMatchAmount(transactionAmount, transactionBonus)),
    ),
    createData(
      <b style={{ color: 'limegreen' }}>
        <u>
          {transactionType && transactionType == TransactionTypeEnum.DEPOSIT ? 'Total to Send' : 'Total to Receive'}
        </u>
      </b>,
      <b style={{ color: 'limegreen' }}>
        <u>
          $
          {getTotalForTransaction(
            transactionType,
            transactionAmount,
            getFeeAmount(
              transactionMethod?.id == PaymentMethodEnum.BONUS && transactionType == TransactionTypeEnum.DEPOSIT
                ? 0
                : transactionAmount,
              transactionMethod?.fee ?? 0,
            ),
          )}
        </u>
      </b>,
    ),
  ]

  const cashappRows = [
    createData('Your Cash Tag', transactionAccount),
    createData('Amount', '$' + transactionAmount),
    createData('Fee', '$' + getFeeAmount(transactionAmount, transactionMethod?.fee ?? 0)),
    createData(getBonusMatch() + ' Bonus', `$${getBonusMatchAmount(transactionAmount, transactionBonus)}`),
    createData(
      transactionType == TransactionTypeEnum.DEPOSIT ? 'Total funded to account' : 'Total withdrawn',
      '$' + getTotal(transactionType, transactionAmount, getBonusMatchAmount(transactionAmount, transactionBonus)),
    ),
    createData(
      <b style={{ color: 'limegreen' }}>
        <u>
          {transactionType == TransactionTypeEnum.DEPOSIT
            ? `Total to Send via ${transactionType == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}`
            : 'Total to Receive'}
        </u>
      </b>,
      <b style={{ color: 'limegreen' }}>
        <u>
          {'$' +
            (transactionMethod?.id == PaymentMethodEnum.BONUS && transactionType == TransactionTypeEnum.DEPOSIT
              ? 0
              : getTotalForTransaction(
                  transactionType,
                  transactionAmount,
                  getFeeAmount(transactionAmount, transactionMethod?.fee ?? 0),
                ))}
        </u>
      </b>,
    ),
  ]

  const venmoRows = [
    createData('Your Venmo Username', transactionAccount),
    createData('Amount', '$' + transactionAmount),
    createData('Fee', '$' + getFeeAmount(transactionAmount, transactionMethod?.fee ?? 0)),
    createData(getBonusMatch() + ' Bonus', `$${getBonusMatchAmount(transactionAmount, transactionBonus)}`),
    createData(
      transactionType == TransactionTypeEnum.DEPOSIT ? 'Total funded to account' : 'Total withdrawn',
      '$' + getTotal(transactionType, transactionAmount, getBonusMatchAmount(transactionAmount, transactionBonus)),
    ),
    createData(
      <b style={{ color: 'limegreen' }}>
        <u>
          {transactionType == TransactionTypeEnum.DEPOSIT
            ? `Total to Send via ${transactionType == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}`
            : 'Total to Receive'}
        </u>
      </b>,
      <b style={{ color: 'limegreen' }}>
        <u>
          {'$' +
            (transactionMethod?.id == PaymentMethodEnum.BONUS && transactionType == TransactionTypeEnum.DEPOSIT
              ? 0
              : getTotalForTransaction(
                  transactionType,
                  transactionAmount,
                  getFeeAmount(transactionAmount, transactionMethod?.fee ?? 0),
                ))}
        </u>
      </b>,
    ),
  ]

  const applepayRows = [
    createData('Apple Pay Account', transactionAccount),
    createData('Amount', '$' + transactionAmount),
    createData('Fee', '$' + getFeeAmount(transactionAmount, transactionMethod?.fee ?? 0)),
    createData(getBonusMatch() + ' Bonus', `$${getBonusMatchAmount(transactionAmount, transactionBonus)}`),
    createData(
      transactionType == TransactionTypeEnum.DEPOSIT ? 'Total funded to account' : 'Total withdrawn',
      '$' + getTotal(transactionType, transactionAmount, getBonusMatchAmount(transactionAmount, transactionBonus)),
    ),
    createData(
      <b style={{ color: 'limegreen' }}>
        <u>
          {transactionType == TransactionTypeEnum.DEPOSIT
            ? `Total to Send via ${transactionType == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}`
            : 'Total to Receive'}
        </u>
      </b>,
      <b style={{ color: 'limegreen' }}>
        <u>
          {'$' +
            (transactionMethod?.id == PaymentMethodEnum.BONUS && transactionType == TransactionTypeEnum.DEPOSIT
              ? 0
              : getTotalForTransaction(
                  transactionType,
                  transactionAmount,
                  getFeeAmount(transactionAmount, transactionMethod?.fee ?? 0),
                ))}
        </u>
      </b>,
    ),
  ]

  const otherRows = [
    createData(
      'Amount',
      '$' +
        (transactionMethod?.id == PaymentMethodEnum.BONUS && transactionType == TransactionTypeEnum.DEPOSIT
          ? 0
          : transactionAmount),
    ),
    createData(
      'Fee',
      '$' +
        getFeeAmount(
          transactionAmount,
          transactionMethod?.id == PaymentMethodEnum.BONUS && transactionType == TransactionTypeEnum.DEPOSIT
            ? 0
            : transactionMethod?.fee ?? 0,
        ),
    ),
    createData(getBonusMatch() + ' Bonus', `$${getBonusMatchAmount(transactionAmount, transactionBonus)}`),
    createData(
      transactionType == TransactionTypeEnum.DEPOSIT ? 'Total funded to account' : 'Total withdrawn',
      '$' +
        getTotal(
          transactionType,
          transactionAmount,
          getBonusMatchAmount(
            transactionMethod?.id == PaymentMethodEnum.BONUS && transactionType == TransactionTypeEnum.DEPOSIT
              ? 0
              : transactionAmount,
            transactionBonus,
          ),
        ),
    ),
    createData(
      <b style={{ color: 'limegreen' }}>
        <u>
          {transactionType == TransactionTypeEnum.DEPOSIT
            ? `Total to Send via ${transactionType == TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}`
            : 'Total to Receive'}
        </u>
      </b>,
      <b style={{ color: 'limegreen' }}>
        <u>
          {'$' +
            (transactionMethod?.id == PaymentMethodEnum.BONUS && transactionType == TransactionTypeEnum.DEPOSIT
              ? 0
              : getTotalForTransaction(
                  transactionType,
                  transactionAmount,
                  getFeeAmount(transactionAmount, transactionMethod?.fee ?? 0),
                ))}
        </u>
      </b>,
    ),
  ]

  const getRows = () => {
    if (
      transactionMethod?.id == PaymentMethodEnum.BITCOIN ||
      transactionMethod?.id == PaymentMethodEnum.INSTANT_BITCOIN
    ) {
      return bitcoinRows
    }
    if (transactionMethod?.id == PaymentMethodEnum.APPLE_PAY) {
      return applepayRows
    }
    if (transactionMethod?.id == PaymentMethodEnum.CASH_APP) {
      return cashappRows
    }

    if (transactionMethod?.id == PaymentMethodEnum.VENMO) {
      return venmoRows
    }
    return otherRows
  }

  const getDate = () => {
    const today = new Date()
    const dd = today.getDate()

    let mmnew = null
    const mm = today.getMonth() + 1
    const yyyy = today.getFullYear()

    if (mm < 10) {
      mmnew = '0' + mm
    } else {
      mmnew = mm
    }

    // const date = mm + "/" + dd + "/" + yyyy;
    const date = yyyy + '-' + mmnew + '-' + (dd < 10 ? '0' + dd : dd)
    return date
  }

  let tos = `VIP Club only accepts BTC to load player accounts. Your fiat currency will be converted to BTC via our integration with our digital currency trading partner, Paxful, in the next step. \n\nPaxful is a digital currency peer-to-peer trading platform. By signing your name below, you are acknowledging that you are purchasing Bitcoin from a trusted digital currency vendor on Paxful. Failing to sign below will stop the process of loading your account.\n\nBy signing below you are agreeing that this transaction is a peer-to-peer transaction with a 3rd party Bitcoin vendor, and this website is only facilitating the connection and plays no role in either side of the transaction. You acknowledge that this is an irreversible and indisputable transaction. Your electronic signature is the legal equivalent of your manual signature.`

  const paypalTos =
    'AI-leads is a lead generator in many industries. AI-leads products include highly qualified leads containing contact information of individuals who have expressed their interest in the said industry through subscribing to an email or SMS list, in order to get updates related to available products and services.\n\nAi-leads is happy to serve you (the customer) with a list of high quality leads (the product) according to the purchased quantity of leads specified in this transaction. The leads are sold to you as the end consumer of the information and are not meant for resale.\n\nBy signing below, you are agreeing that this transaction is final and indisputable, you will not spam the contacts in the leads list for the purpose of anything other than the field of their expressed interest, as described above, and you will not resell the leads to other individuals or companies.'

  const stripeTos =
    'AI-leads is a lead generator in many industries. AI-leads products include highly qualified leads containing contact information of individuals who have expressed their interest in the said industry through subscribing to an email or SMS list, in order to get updates related to available products and services.\n\nAi-leads is happy to serve you (the customer) with a list of high quality leads (the product) according to the purchased quantity of leads specified in this transaction. The leads are sold to you as the end consumer of the information and are not meant for resale.\n\nBy signing below, you are agreeing that this transaction is final and indisputable, you will not spam the contacts in the leads list for the purpose of anything other than the field of their expressed interest, as described above, and you will not resell the leads to other individuals or companies.'

  if (transactionMethod != undefined && transactionMethod != null) {
    if (transactionMethod?.id == PaymentMethodEnum.PAYPAL) {
      tos = paypalTos
    }

    if (
      [PaymentMethodEnum.DEBIT_CARD, PaymentMethodEnum.INSTANT_APPLE_PAY, PaymentMethodEnum.CASH_APP_STRIPE].some(
        (x) => x == transactionMethod?.id,
      )
    ) {
      tos = stripeTos
    }
  }

  useEffect(() => {
    if (
      (agreedFee && agreed && disableSubmit && agreedStripeReceipt) ||
      (transactionType &&
        (transactionType == TransactionTypeEnum.WITHDRAWAL ||
          [PaymentMethodEnum.BITCOIN, PaymentMethodEnum.BONUS].indexOf(transactionMethod?.id ?? -1) != -1))
    ) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [agreed, agreedFee, agreedStripeReceipt])

  return (
    <>
      <div className='mx-auto p-6 text-center max-w-md'>
        <div className='text-center text-primary mb-6 border-b-2 border-primary inline-flex justify-center items-center'>
          <div className='inline-block mb-4'>
            <img
              src={cdn + (transactionMethod?.image ?? '')}
              alt={transactionMethod?.name}
              className='w-16 h-16 mr-2 object-contain'
            />
          </div>
          <h2 className='text-xl'>
            {transactionMethod?.name}{' '}
            {transactionMethod?.id !== PaymentMethodEnum.BONUS
              ? transactionType === TransactionTypeEnum.DEPOSIT
                ? 'Deposit'
                : 'Withdrawal'
              : ''}
          </h2>
        </div>

        {transactionMethod?.id !== PaymentMethodEnum.CASH_APP && (
          <div className='bg-dark-800 border-l-4 border-yellow-500 text-yellow-500 p-4 mb-6 rounded'>
            <CircleAlert className='inline mr-2 mb-1' />
            {transactionMethod?.id === PaymentMethodEnum.CASH_APP && (
              <span>
                Please double check <b>your cash app tag</b> before submitting your request.
              </span>
            )}
            {transactionMethod?.id === PaymentMethodEnum.VENMO && (
              <span>
                Please double check <b>your venmo username</b> before submitting your request.
              </span>
            )}
            {(transactionMethod?.id === PaymentMethodEnum.BITCOIN ||
              transactionMethod?.id === PaymentMethodEnum.INSTANT_BITCOIN) && (
              <span>
                Please review your <b>{transactionType === TransactionTypeEnum.DEPOSIT ? 'Deposit' : 'Withdrawal'}</b>{' '}
                info before submitting your request.
              </span>
            )}
            {transactionMethod?.id === PaymentMethodEnum.APPLE_PAY && (
              <span>
                Please double check <b>your apple pay info</b> before submitting your request.
              </span>
            )}
            {transactionMethod?.id === PaymentMethodEnum.PAYPAL && (
              <span>
                Please double check your <b>deposit information</b> before submitting your request.
              </span>
            )}
            {transactionMethod?.id === PaymentMethodEnum.INSTANT_APPLE_PAY && (
              <span>
                You MUST be starting this transaction on an Apple Pay enabled device, and have pop-ups enabled.
              </span>
            )}
            {(transactionMethod?.id === PaymentMethodEnum.ZELLE ||
              transactionMethod?.id === PaymentMethodEnum.DEBIT_CARD) && (
              <span>
                Please double check your <b>deposit information</b> before submitting your request.
              </span>
            )}
            {transactionMethod?.id === PaymentMethodEnum.INSTANT_PAY && (
              <span>Please confirm transaction details and verify the code that has been sent to your phone.</span>
            )}
          </div>
        )}

        <div className='flex justify-between items-center mb-4'>
          <button
            className='bg-transparent border-2 border-primary hover:opacity-50 text-white py-2 px-4 rounded'
            onClick={() => {
              setCurrentRoute(CashierRoutes.INFO)
            }}
          >
            Back
          </button>

          {!(
            transactionType === TransactionTypeEnum.WITHDRAWAL &&
            user.withdrawal_requires_verification_code &&
            smsCodeCheck == null
          ) && (
            <button
              className={`bg-primary text-white hover:opacity-50 py-2 px-4 rounded ${
                disableSubmit ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={disableSubmit}
              onClick={handleInsertTransaction}
            >
              {transactionType === TransactionTypeEnum.DEPOSIT ? 'Continue Deposit' : 'Request Withdrawal'}
            </button>
          )}
        </div>
      </div>

      <ConfirmTable rows={getRows()} bonus={transactionBonus} />

      {/* TOS Section */}
      {transactionType === TransactionTypeEnum.DEPOSIT &&
        transactionMethod?.id &&
        !['BITCOIN', 'INSTANT_BITCOIN', PaymentMethodEnum.BONUS].includes(transactionMethod?.id) && (
          <>
            <textarea
              value={tos}
              readOnly
              className='w-full mt-4 p-4 rounded border border-gray-800 bg-gray-800 text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500'
              rows={4}
              placeholder='Terms and conditions'
            ></textarea>

            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4'>
              <div>
                <input
                  type='text'
                  placeholder='Your Full Name'
                  className='w-full p-2 rounded border border-gray-300 bg-gray-50 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500'
                  value={transactionName ?? ''}
                  onChange={(e) => setTransactionName(e.target.value)}
                />
              </div>
              <div>
                <input
                  type='date'
                  value={getDate()}
                  readOnly
                  className='w-full p-2 rounded border border-gray-300 bg-gray-100 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500'
                />
              </div>
            </div>

            <div className='mt-4'>
              <label className='flex items-center text-white'>
                <input
                  type='checkbox'
                  checked={agreed}
                  onChange={handleAgree}
                  className='form-checkbox h-6 w-6 text-white accent-yellow-600 transition duration-150 ease-in-out'
                />
                <span className='ml-2'>I have read and I agree with the terms and conditions of this transaction</span>
              </label>
            </div>

            {isStripeMethod(transactionMethod?.id) && (
              <div className='mt-4'>
                <label className='flex items-center text-white'>
                  <input
                    type='checkbox'
                    checked={agreedStripeReceipt}
                    onChange={(event) => setAgreedStripeReceipt(event.target.checked)}
                    className='form-checkbox h-6 w-6 text-white accent-yellow-600 transition duration-150 ease-in-out'
                  />
                  <span className='ml-2'>I acknowledge I will be sent receipt from this transaction</span>
                </label>
              </div>
            )}

            <div className='mt-4'>
              <label className='flex items-center text-white'>
                <input
                  type='checkbox'
                  checked={agreedFee}
                  onChange={handleAgreeFee}
                  className='form-checkbox h-6 w-6 text-white accent-yellow-600 transition duration-150 ease-in-out'
                />
                <span className='ml-2'>I agree to pay the posted fee above</span>
              </label>
            </div>
          </>
        )}

      {/* Withdrawal SMS Section */}
      {transactionType === TransactionTypeEnum.WITHDRAWAL &&
      user.withdrawal_requires_verification_code &&
      smsCodeCheck == null ? (
        <div className='text-center my-4'>
          <div className='bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 rounded-md'>
            <div className='font-bold text-lg mb-2'>Confirm Code</div>
            <p className='text-sm mb-4'>
              We have sent a SMS or EMAIL confirmation code. <br />
              Please confirm the verification code sent to your mobile device.
            </p>

            <input
              type='text'
              placeholder='Verification Code'
              className='w-full p-2 mb-4 rounded border border-gray-300 bg-gray-50 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500'
              onChange={(e) => setVerificationCode(e.target.value)}
              value={verificationCode}
            />

            <div className='flex justify-center space-x-4'>
              <button
                className='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded'
                onClick={() => sendSmsCode(user.username)}
              >
                Resend Code
              </button>

              <button
                className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded'
                // TODO handle response here
                onClick={() => validateSmsCode({ username: user.username, code: verificationCode })}
              >
                Confirm Code
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
