import React, { FC } from 'react'
import './AnimationsModal.scss'

type AnimationsModalType = {
  isVisible: boolean
  message: string
  state: 'idle' | 'loading' | 'success' | 'error'
}

const AnimationsModal: FC<AnimationsModalType> = ({ isVisible, message, state }) => {
  return (
    <div className={`animations-modal ${isVisible ? 'visible' : ''} ${state}`}>
      <div className='modal-content'>
        <div className='lds-circle'>
          <div></div>
        </div>
        <p dangerouslySetInnerHTML={{ __html: message }}></p>
      </div>
    </div>
  )
}

export default AnimationsModal
