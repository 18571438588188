import React, { ChangeEvent, forwardRef, ForwardedRef } from 'react'
import './styles.scss'

interface InputProps {
  label: string
  placeholder: string
  variant: 'bordered'
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  type?: string
  width?: string
  error?: boolean
  required?: boolean
  id?: string
}

const CommonInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      placeholder,
      variant,
      value,
      onChange,
      onBlur,
      type = 'text',
      width = '100%',
      error = false,
      required = false,
      id = 'input',
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <div className='mb-4 inputContainer' style={{ width }}>
        <label className='block text-white-700 text-sm font-light my-2' htmlFor={id}>
          {label}
          {!required && (
            <>
              <span className='text-red-500'> - </span>
              <span className='italic text-xs'>Optional</span>
            </>
          )}
        </label>
        <input
          type={type}
          name={id}
          id={id}
          className={`
            bg-white border text-gray-900 sm:text-sm rounded-lg 
            block w-full p-1.5
            focus:outline-none focus:ring-2 focus:ring-[#c8a964] focus:border-[#c8a964]
            ${variant === 'bordered' ? 'bordered' : ''}
            ${error ? 'border-red-500' : 'border-gray-300'}
          `}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          ref={ref}
        />
      </div>
    )
  },
)

export default CommonInput
