import React from 'react'

export interface ExternalProps {
  open: boolean
  data: React.ReactNode
}

export interface ExternalActionProps {
  onClose: () => void
}

export const WageringDialoge = (props: ExternalProps & ExternalActionProps) => {
  const { open, data, onClose } = props

  if (!open) return null

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-background text-white rounded-lg shadow-lg w-11/12 lg:w-2/3 max-w-5xl p-6'>
        {/* Dialog Title */}
        <div className='flex justify-between items-center border-b border-primary pb-4 mb-4'>
          <h2 className='text-2xl text-primary'>Wagering History</h2>
          <button onClick={onClose} className='text-gray-500 hover:text-gray-700 focus:outline-none text-xl'>
            &times;
          </button>
        </div>

        {/* Dialog Content */}
        <div className='mb-6'>{data}</div>

        {/* Dialog Actions */}
        <div className='flex justify-end'>
          <button
            onClick={onClose}
            className='px-4 py-2 bg-transparent border-2 border-primary text-white rounded hover:opacity-50'
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
