import React, { useState } from 'react'
import { motion } from 'framer-motion'

const YouTubeThumbnail = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoId = 'tunXmNtSr38'

  return (
    <div className='relative w-full h-56'>
      {!isPlaying ? (
        <motion.div
          className='cursor-pointer relative w-full h-full'
          onClick={() => setIsPlaying(true)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
            <button className='text-white bg-primary rounded-full p-3'>▶️ Play</button>
          </div>
        </motion.div>
      ) : (
        <motion.iframe
          width='100%'
          height='100%'
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title='YouTube video player'
          frameBorder='0'
          loading='lazy'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          className='w-full h-full rounded'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        ></motion.iframe>
      )}
    </div>
  )
}

export default YouTubeThumbnail
