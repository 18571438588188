import React, { FC, useState, useEffect } from 'react'
import { X } from 'lucide-react'
import './WelcomeModal.scss'

type WelcomeModalType = {
  username: string
  userEmail: string
  onClose: () => void
  onClaimOffer?: () => void
}

const WelcomeModal: FC<WelcomeModalType> = ({ username, userEmail, onClose, onClaimOffer }) => {
  const [timeLeft, setTimeLeft] = useState(24 * 60 * 60) // 24 hours in seconds

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)
      return () => clearTimeout(timerId)
    }
  }, [timeLeft])

  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = time % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-opacity-50 z-50'>
      <div className='relative bgModal p-6 rounded-lg max-w-md w-full'>
        <button onClick={onClose} className='absolute top-2 right-2 text-gray-400 hover:text-white'>
          <X size={24} />
        </button>
        <div className='flex flex-col items-center justify-center space-y-4'>
          <div className='space-y-2 text-center'>
            <h3 className='text-2xl font-bold text-white'>Welcome to VIPClub!</h3>
            <h4 className='text-xl font-bold text-white'>Your username is: {username}</h4>
            <p className='text-gray-300 mb-5'>
              We&apos;ve sent a verification link to <b>{userEmail}</b>. Check your inbox and click the link to complete
              your registration.
            </p>
          </div>
          {onClaimOffer ? (
            <>
              <p className='text-gray-300 mt-50'>Don&apos;t miss out on our exclusive promotional offer.</p>
              <div className='flex items-center space-x-2 text-lg font-medium text-white'>
                <div className='gift'></div>
                <span>Offer ends in:</span>
                <div className='flex items-center space-x-1 text-primary'>
                  <span className='font-bold'>{formatTime(timeLeft)} hs</span>
                </div>
              </div>
              <button
                onClick={onClaimOffer}
                className='px-4 py-2 bg-[#d4af37] text-[#1a1a1a] rounded hover:bg-[#c8a72f] focus:ring-2 focus:ring-[#d4af37] focus:outline-none font-bold'
              >
                Claim Offer
              </button>
            </>
          ) : (
            <button
              onClick={onClose}
              className='px-4 py-2 bg-[#d4af37] text-[#1a1a1a] rounded hover:bg-[#c8a72f] focus:ring-2 focus:ring-[#d4af37] focus:outline-none font-bold'
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default WelcomeModal
